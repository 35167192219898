import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { MenuStructure, UIProps } from '../../types';
import { useModalHook } from '../../hooks';

import logo from '../../assets/logo_iproxy.svg';
import faqIcon from '../../assets/menu_icon/faq.svg';
import supportIcon from '../../assets/menu_icon/support.svg';
import transactionIcon from '../../assets/menu_icon/transactions.svg';
import downloadIcon from '../../assets/menu_icon/download_app.svg';
import refferalIcon from '../../assets/menu_icon/refferal.svg';
import apiIcon from '../../assets/menu_icon/api.svg';
import profileIcon from '../../assets/menu_icon/profile.svg';
import walletIcon from '../../assets/menu_icon/wallet.svg';

import blueWalletIcon from '../../assets/menu_icon/blue_wallet.svg';
import blueApiIcon from '../../assets/menu_icon/blue_api.svg';
import blueProfileIcon from '../../assets/menu_icon/blue_profile.svg';
import blueReferralIcon from '../../assets/menu_icon/blue_referral.svg';
import blueTransactionIcon from '../../assets/menu_icon/blue_transactions.svg';
import blueSupportIcon from '../../assets/menu_icon/blue_telegram.svg';
import blueDownloadIcon from '../../assets/menu_icon/blue_download.svg';
import bluefaqIcon from '../../assets/menu_icon/blue_faq.svg';

import hamburgerIcon from '../../assets/menu_icon/hamburger.svg';

import { analyticsClassnames } from '../../utils/analytics-utils';
import { windowOpen } from '../../utils';
import { getDownloadLink } from '../../utils/instruction-links';
import { CloseBt } from './ModalComponent';
import {
  BREAKPOINT_MOBILE,
  telegramSupport,
  TARGET_BLANK,
  TARGET_SELF,
  LINK_REL,
} from '../../utils/constants';
import { useIsMobileHook } from '../../hooks/useIsMobileHook';

interface MobileIconProps {
  expanded: boolean;
  onExpanded: (value: boolean) => void;
}

const MobileIcon = (props: MobileIconProps) => {
  const {
    expanded,
    onExpanded = () => {},
  } = props;

  return (
    <MobileSwitchIconPlaceholder
      expanded={expanded}
      onClick={() =>
        onExpanded(!expanded)}
    >
      {
        expanded ? (
          <CloseBt />
        ) : (
          <SwitchMobileIcon
            src={hamburgerIcon}
            height="28px"
          />
        )
      }

    </MobileSwitchIconPlaceholder>
  );
};

export function TopMenuComponent(props: any) {
  const modalOptions = useModalHook();

  const { t, i18n } = useTranslation();
  const isMobile = useIsMobileHook();

  const { isPublic = false, expanded, onExpanded } = props;

  const menu: MenuStructure[] = [
    {
      title: `${t('wallet.balanceTopUp')}`,
      key: 'balance-screen',
      onClick: () => {
        modalOptions.show('balance.topUp');
      },
      isExternal: false,
      icon: walletIcon,
      blueIcon: blueWalletIcon,
      className: analyticsClassnames.wallet,
    },
    {
      title: `${t('topMenu.transaction')}`,
      isExternal: false,
      icon: transactionIcon,
      blueIcon: blueTransactionIcon,
      key: 'transaction-screen',
      onClick: () => {
        modalOptions.show('screen.transactions');
      },
    },
    {
      title: `${t('topMenu.referral')}`,
      key: 'referral-screen',
      onClick: () => {
        modalOptions.show('screen.referal');
      },
      isExternal: false,
      icon: refferalIcon,
      blueIcon: blueReferralIcon,
    },
    {
      title: `${t('topMenu.downloadApp')}`,
      isExternal: true,
      newTab: true,
      key: 'download-app-link',
      icon: downloadIcon,
      blueIcon: blueDownloadIcon,
      onClick: () =>
        windowOpen(getDownloadLink(i18n.language)),
    },
    {
      title: `${t('topMenu.api')}`,
      key: 'api',
      onClick: () => {
        modalOptions.show('screen.api');
      },
      isExternal: false,
      icon: apiIcon,
      blueIcon: blueApiIcon,
    },
    {
      title: `${t('topMenu.faq')}`,
      link: `https://iproxy.online/${i18n.language}/faq/`,
      newTab: true,
      isExternal: true,
      icon: faqIcon,
      blueIcon: bluefaqIcon,
      key: 'faq-link',
    },
    {
      title: `${t('topMenu.support')}`,
      link: telegramSupport,
      newTab: true,
      isExternal: true,
      icon: supportIcon,
      blueIcon: blueSupportIcon,
      key: 'support-link',
    },
    {
      title: `${t('topMenu.profile')}`,
      icon: profileIcon,
      key: 'profile-screen',
      blueIcon: blueProfileIcon,
      onClick: () => {
        modalOptions.show('screen.profile');
      },
    },
  ];

  return (
    <>
      {
        isMobile ? (
          <MobileWrapper>
            <TopWhiteMobilePlaceholder expanded={expanded}>
              <MobileNamePlaceholder>
                <LogoMobile icon={logo} />
              </MobileNamePlaceholder>

              <MobileIcon
                expanded={expanded}
                onExpanded={onExpanded}
              />
            </TopWhiteMobilePlaceholder>

            {expanded === false || isPublic ? null : (
              <MobileExpandedContent>
                <MobileExpandedContentInnerPlaceholder>
                  <TopWrapper>
                    <MobileTitle>{t('topMenu.title')}</MobileTitle>
                    <MobileIcon
                      expanded={expanded}
                      onExpanded={onExpanded}
                    />
                  </TopWrapper>
                  {menu.map((item: MenuStructure) =>
                    (
                      <MobileMenuItem
                        key={`index_mobile_${item.key}`}
                        className={item.className}
                        onClick={() => {
                          if (item?.onClick != null) {
                            onExpanded(false);
                            item?.onClick();
                          }
                        }}
                      >
                        <MenuItemIcon icon={item?.blueIcon} />
                        <MobileLink
                          href={item?.link}
                          target={!item?.newTab ? TARGET_SELF : TARGET_BLANK}
                          title={item.title}
                          rel={LINK_REL}
                        >
                          {item.title}
                        </MobileLink>
                      </MobileMenuItem>
                    ))}
                </MobileExpandedContentInnerPlaceholder>
              </MobileExpandedContent>
            )}
          </MobileWrapper>
        ) : (
          <Wrapper>
            {!isPublic ? (
              <>
                <Logo
                  onClick={() => {
                    window.location.href = '/';
                  }}
                />
                <ControlContainer>
                  <MenuContainer>
                    {menu.map((item: MenuStructure) =>
                      (
                        <MenuItem
                          key={item.key}
                          className={item.className}
                          onClick={() => {
                            if (item?.onClick != null) {
                              item?.onClick();
                            }
                          }}
                        >
                          <MenuItemIcon icon={item?.icon} />
                          <Link
                            href={item?.link}
                            target={!item?.newTab ? TARGET_SELF : TARGET_BLANK}
                            title={item.title}
                            rel={LINK_REL}
                          >
                            {item.title}
                          </Link>
                        </MenuItem>
                      ))}
                  </MenuContainer>
                </ControlContainer>
              </>
            ) : null}
          </Wrapper>
        )
      }

    </>
  );
}

const MobileMenuItem = styled.div`
  margin: 0 0 10px -6px;
  padding: 6px;
  line-height: 1 !important;

  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  position: relative;
  box-sizing: content-box;
  display: inline-flex;
  text-align: center;
  align-items: center;
  font-weight: normal;
  cursor: pointer;

  :before {
    content: '';
    position: absolute;
    border-bottom: 2px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    width: 100%;
    bottom: -5px;
    opacity: 0;
    left: 0;
  }

  :hover {
    background-color: ${(props: UIProps) =>
    props.theme.colors.brightBlue};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

    color: ${(props: UIProps) =>
    props.theme.colors.white};

    :before {
      opacity: 1;
    }
  }

  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
`;

const MobileLink = styled.a`
  text-decoration: none;
  display: inline-block;
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  box-sizing: content-box;
  transition: 0.3s;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};

  :before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

const MobileExpandedContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  bottom: 0px;
  width: 100%;
  background: ${(props: UIProps) =>
    props.theme.colors.white};
`;

const MobileExpandedContentInnerPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallMedium};
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MobileWrapper = styled.div`
  display: block;
  max-height: 100vh;
  box-sizing: border-box;
`;

const SwitchMobileIcon = styled.img`
  height: ${(props) =>
    props.height};
  cursor: pointer;
`;

const MobileNamePlaceholder = styled.div`
  display: flex;
  flex: 1 1;
  align-items: center;
  white-space: nowrap;
  overflow-x: hidden;
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
`;

interface MobileSwitchIconPlaceholderProps {
  expanded?: boolean;
}

const MobileSwitchIconPlaceholder = styled.div<MobileSwitchIconPlaceholderProps>`
  display: flex;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};
  height: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    (props.expanded ? 'rgba(36, 78, 178, .04)' : 'transparent')};
`;

interface TopWhiteMobilePlaceholderProps extends UIProps {
  expanded?: boolean;
}

const TopWhiteMobilePlaceholder = styled.div<TopWhiteMobilePlaceholderProps>`
  position: ${(props) =>
    (props.expanded ? 'fixed' : 'static')};
  top: 0;
  left: 0;
  z-index: 3;

  display: ${(props) =>
    (props.expanded ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  border-bottom-left-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};
  border-bottom-right-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};

  box-sizing: border-box;
  background: ${(props) =>
    (props.expanded ? props.theme.colors.white : props.theme.colors.deepBlue)};

  -webkit-box-shadow: ${(props) =>
    (props.expanded ? 'none' : `0px 2px 16px -6px ${props.theme.colors.black}`)};
  box-shadow: ${(props) =>
    (props.expanded ? 'none' : `0px 2px 16px -6px ${props.theme.colors.black}`)};
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 63px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ControlContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Logo = styled.div`
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  width: 125px;
  height: 70px;
  background-size: contain;
  cursor: pointer;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 410px;
  position: relative;
  width: 100%;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    box-sizing: border-box;
    min-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const MenuItem = styled.div`
  margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  cursor: pointer;
  position: relative;
  box-sizing: content-box;
  display: inline-flex;
  text-align: center;
  align-items: center;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  :before {
    content: '';
    position: absolute;
    border-bottom: 2px solid ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    width: 100%;
    bottom: -5px;
    opacity: 0;
    left: 0;
  }

  :hover {
    background-color: ${(props: UIProps) =>
    props.theme.colors.brightBlue};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

    color: ${(props: UIProps) =>
    props.theme.colors.white};

    :before {
      opacity: 1;
    }
  }
`;

interface MenuItemIconProps {
  icon?: string;
}

const MenuItemIcon = styled.div<MenuItemIconProps>`
  width: 16px;
  height: 16px;
  margin: 0 5px 0 0;
  background-image: url(${(props: MenuItemIconProps) =>
    props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: 0 10px 0 0;
  }
`;

const Link = styled.a`
  text-decoration: none;
  display: inline-block;
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  box-sizing: content-box;
  transition: 0.3s;
   ${(props: UIProps) =>
    props.theme.sizes.font.smaller};

  :before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

interface IconProps {
  icon?: string;
}

const MobileTitle = styled.h3`
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const LogoMobile = styled.div`
  width: 125px;
  height: 19px;

  background-image: url(${(props: IconProps) =>
    props.icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
