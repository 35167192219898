import styled from 'styled-components';
import { UIProps } from '../types/theme';
import theme from '../styles/ApplicationTheme';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../utils/constants';

interface TransactionsContainerProps extends UIProps {
  minWidth?: string;
  margin?: string;
  paddingRight?: string;
}
export const TransactionsContainer = styled.div<TransactionsContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: ${(props: TransactionsContainerProps) =>
    props.minWidth};
  box-sizing: border-box;
  margin: ${(props: TransactionsContainerProps) =>
    props.margin};
  padding-right: ${(props: TransactionsContainerProps) =>
    props.paddingRight};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    min-width: auto;
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    margin: ${(props: TransactionsContainerProps) =>
    props.margin};
  }
`;

interface TransactionsRowProps extends UIProps {
  isNegative?: boolean;
}
export const TransactionsRow = styled.div<TransactionsRowProps>`
  border: 1px solid #b4b4b4;
  box-sizing: border-box;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  display: flex;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  justify-content: space-between;

  .phone-name {
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    color: ${(props: UIProps) =>
    props?.theme?.colors?.darkGray};
  }

  .title {
    font-weight: 500;
    color: ${(props: TransactionsRowProps) =>
    (props?.isNegative
      ? props.theme.colors.deepBlue
      : props.theme.colors.greenMud)};
  }

  .time {
    text-align: right;
    margin-right:${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }

  .amount {
    font-weight: 500;
    text-align: left;
    width: 250px;
    color: ${(props: TransactionsRowProps) =>
    (props?.isNegative
      ? props.theme.colors.warning
      : props.theme.colors.deepBlue)};
  }

  .ip {
      max-width: 100px;
  }

  .right {
    display: flex;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  }

  .left {
    display: flex;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }
`;

export const Error = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};
`;

export const Time = styled.div`
  width: 150px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

interface ScrollBarContainerProps extends UIProps {
  flex?: boolean;
}
export const ScrollBarContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 32px;
  min-width: 800px;
  display: ${(props: ScrollBarContainerProps) =>
    props.flex && 'flex'};
  flex-direction: ${(props: ScrollBarContainerProps) =>
    props.flex && 'column'};

  @media only screen and (max-width: ${BREAKPOINT_TABLET}px) {
    min-width: 100%;
  }
`;

interface NewsBoxProps extends UIProps {
  width?: string;
  marginBottom?: string;
  titleHeight?: string;
  titleFontSize?: string;
  titleLineHeight?: string;
  margin?: string;
  imageWidth?: string;
  imageHeight?: string;
  contentHeight?: string;
  contentFontSize?: string;
  contentLineHeight?: string;
}
export const NewsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props: NewsBoxProps) =>
    props.width};
  margin-bottom: ${(props: NewsBoxProps) =>
    props.marginBottom};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};

  @media only screen and (max-width: ${BREAKPOINT_TABLET}px) {
    width: 100%;
  }

  .link:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .image {
    width: ${(props: NewsBoxProps) =>
    props.imageWidth};
    height: ${(props: NewsBoxProps) =>
    props.imageHeight};
    background-color: ${(props: UIProps) =>
    props.theme.colors.spaceGray};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    position: relative;

    @media only screen and (max-width: ${BREAKPOINT_TABLET}px) {
      width: 65%;
      height: calc(60vw / 1.8);
      margin: auto;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    }

    .isNew {
      position: absolute;
      width: 52px;
      height: 25px;
      left: 229px;
      top: 8px;
      background-color: ${(props: UIProps) =>
    props.theme.colors.white};
      border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

      display: flex;
      justify-content: center;
      align-items: center;

      ${(props: UIProps) =>
    props.theme.sizes.font.smallerBold};
      color: ${(props: UIProps) =>
    props.theme.colors.greenMud};

    }
  }

  .title {
    height: ${(props: NewsBoxProps) =>
    props.titleHeight};
    font-size: ${(props: NewsBoxProps) =>
    props.titleFontSize};
    line-height: ${(props: NewsBoxProps) =>
    props.titleLineHeight};
    font-weight: 500;
    margin: ${(props: NewsBoxProps) =>
    props.margin};
    color: ${(props: UIProps) =>
    props.theme.colors.dark};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .content {
    height: ${(props: NewsBoxProps) =>
    props.contentHeight};
    font-size: ${(props: NewsBoxProps) =>
    props.contentFontSize};
    line-height: ${(props: NewsBoxProps) =>
    props.contentLineHeight};
    color: ${(props: UIProps) =>
    props.theme.colors.dark};

    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    width: 100%;
    height: 15px;
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
    margin: 12px 0px 12px 0px;
  }

  .read {
    width: 85.5px;
    ${(props: UIProps) =>
    props.theme.sizes.font.smallSemibold};
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (min-width: ${BREAKPOINT_MOBILE}px) and (max-width: ${BREAKPOINT_TABLET}px) {
    width: 210px;
    margin: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }

  @media only screen and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;

interface ScreenInfoProps extends UIProps {
  maxWidth?: string;
  marginBottom?: string;
  marginTop?: string;
  icon?: string;
  justifyContent?: boolean;
  alignItems?: boolean;
  height?: string;
  width?: boolean;
  flex?: boolean;
}
export const ScreenInfo = styled.div<ScreenInfoProps>`
  display: ${(props: ScreenInfoProps) =>
    props.flex && 'flex'};
  margin-bottom: ${(props: ScreenInfoProps) =>
    props.marginBottom};
  max-width: ${(props: ScreenInfoProps) =>
    props.maxWidth};
  justify-content: ${(props: ScreenInfoProps) =>
    props.justifyContent && 'space-between'};
  align-items: ${(props: ScreenInfoProps) =>
    props.alignItems && 'center'};
  height: ${(props: ScreenInfoProps) =>
    props.height};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: ScreenInfoProps) =>
    props.width && 'width: calc(100% - 50px)'};
  }

  .goback {
    color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
    cursor: pointer;
    margin-right: 25px;
    display: flex;
    align-items: center;

    :before {
      content: '';
      display: block;
      background-image: url(${(props: ScreenInfoProps) =>
    props.icon});
      background-position: center;
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    }

    :hover{
      color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    }
  }

  .title {
    ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  }

  .description {
    color: ${(props: UIProps) =>
    props.theme.colors.dark};
    ${(props: UIProps) =>
    props.theme.sizes.font.medium};
    font-weight: 500;
    margin-top: ${(props: ScreenInfoProps) =>
    props.marginTop};
  }

  .sub-description {
    color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .warning {
    display: inline;
    color: ${(props: UIProps) =>
    props.theme.colors.warning};
  }
`;

export interface LogOutProps extends UIProps {
  fontWeight?: string;
  marginRight?: string;
  width?: string;
  height?: string;
  icon?: string;
  borderRadius?: boolean;
  red?: boolean;
}
export const LogOut = styled.div<LogOutProps>`
  display: flex;
  align-items: center;

  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: ${(props: LogOutProps) =>
    (props.red
      ? props.theme.colors.warning
      : props.theme.colors.white)
};
  font-weight: ${(props: LogOutProps) =>
    props.fontWeight};

  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    margin-right: 50px;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  &:before {
    margin-right: ${(props: LogOutProps) =>
    props.marginRight};
    margin-top: -1px;
    width: ${(props: LogOutProps) =>
    props.width};
    height: ${(props: LogOutProps) =>
    props.height};
    display: block;
    content: "";
    background-image: url(${(props: LogOutProps) =>
    props.icon});
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${(props: UIProps) =>
    props.theme.colors.white};
    border-radius: ${((props: LogOutProps) =>
    props.borderRadius && '50%')};
  }
`;

export const InfoBlock = styled.div`
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  background-color: ${theme.colors.lightOcean};
  border: 1px solid ${theme.colors.astrall};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  margin-top: 12px;
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  height: 100%;

  .text {
    font-weight: 500;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
  }
`;
