import React, {
  useState,
  useEffect,
  useRef,
  FunctionComponent,
  useMemo,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Phone, SmsHistory } from '../../../types/phones';
import store from '../../../core/store/store';
import { loadPhoneSmsHistory } from '../../../core/store/actions/connections';
import { formatDate, saveToFile } from '../../../utils';
import { useOnClickOutside } from '../../../hooks';
import { InputLabel } from '../../../styles/ui-controls';
import { UIProps } from '../../../types';
import { CustomeDateInputAtom } from '../../atoms';
import ScrollWrapper from '../../common/ScrollWrapper';
import { dateFormats, BREAKPOINT_MOBILE } from '../../../utils/constants';
import { EmptyMessage } from '../../common/EmptyMessage';
import {
  Wrapper,
  ControlBoard,
  HistoryContainer,
  HistoryRow,
  HeaderHistoryRow,
  Item,
  ItemHeader,
  DownloadHistory,
} from './IpHistoryForm';

interface SmsHistoryFormProps {
  phone?: Phone;
  hasSmsPermission?: boolean;
}

const cssStyle = {
  idContainer: {
    width: '80px',
    minWidth: '50px',
  },
  date: {
    width: '185px',
    minWidth: '80px',
  },
  message: {
    width: '390px',
    minWidth: '150px',
  },
  from: {
    width: '35px',
    minWidth: '50px',
  },
};

const pageResult = 100;

export const SmsHistoryForm: FunctionComponent<SmsHistoryFormProps> = (props) => {
  const { phone, hasSmsPermission } = props;
  const [startDate, setStartDate] = useState(
    +new Date(moment().subtract(1, 'weeks').startOf('isoWeek').toISOString()),
  );
  const [endDate, setEndDate] = useState(+new Date());
  const [page] = useState(0);
  const dateContainer = useRef(null);
  const [dateSort, setDateSort] = useState<'asc' | 'desc'>('desc');

  const [activeData, setActiveData] = useState({
    start: false,
    end: false,
  });
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  useEffect(() => {
    if (phone != null && startDate != null && endDate != null) {
      store.dispatch(
        loadPhoneSmsHistory.request({
          phoneId: phone.id,
          from: +new Date(startDate),
          to: +new Date(endDate),
        }),
      );
    }
  }, [phone === undefined ? '' : phone.id, startDate, endDate]);

  const results = useMemo(() =>
    phone?.smsHistory
      ?.sort((a, b) =>
        (dateSort === 'desc'
          ? +new Date(b?.t) - +new Date(a.t)
          : +new Date(a?.t) - +new Date(b.t)))
      ?.reduce((accum, value) => {
        if (
          !accum.length
              || (accum.length && accum[accum.length - 1]?.length >= pageResult)
        ) {
          accum.push([value]);
        } else {
          accum[accum.length - 1].push(value);
        }
        return accum;
      }, []) || [], [phone, pageResult, dateSort]);

  const $closeCalendars = () =>
    setActiveData({ start: false, end: false });

  const $saveAsFile = () => {
    if (phone.smsHistory == null) return;
    const filename = `sms_${formatDate(moment(), dateFormats.fileDateAlt, locale)}.txt`;
    const data = phone?.smsHistory
      .sort((a, b) =>
        +b.t - +a.t)
      .map((a) =>
        `${a.from} | ${a.message} | ${formatDate(a.t, dateFormats.dateTimeSec, locale)}`)
      .join('\n');
    saveToFile(filename, data);
  };

  useOnClickOutside(dateContainer, () =>
    $closeCalendars());

  return (
    <ScrollWrapper>
      <Wrapper>
        <ControlBoard>
          <TimePickerContent ref={dateContainer}>
            <div className="date_wrap">
              <InputLabel className="label">{`${t('rotation.from')}:`}</InputLabel>
              <CustomeDateInputAtom
                indicator
                disabled={!hasSmsPermission}
                value={new Date(startDate)}
                activeData={activeData.start}
                locale={i18n.language}
                onChange={(e) =>
                  setStartDate(+moment(new Date(e)).startOf('day'))}
              />
            </div>

            <div className="date_wrap">
              <InputLabel className="label">{t('rotation.to')}</InputLabel>
              <CustomeDateInputAtom
                indicator
                disabled={!hasSmsPermission}
                value={new Date(endDate)}
                activeData={activeData.end}
                locale={i18n.language}
                minDate={startDate}
                onChange={(e) =>
                  setEndDate(+moment(new Date(e)).endOf('day'))}
              />
            </div>
          </TimePickerContent>
        </ControlBoard>

        {results?.length > 0 && hasSmsPermission ? (
          <>
            <HistoryContainer>
              <HeaderHistoryRow>
                <ItemHeader header style={{ ...cssStyle.idContainer }}>
                  {t('rotation.number')}
                </ItemHeader>
                <ItemHeader
                  style={{ ...cssStyle.date }}
                  hover
                  header
                  onClick={() =>
                    setDateSort(dateSort === 'desc' ? 'asc' : 'desc')}
                >
                  {t('rotation.date')}
                </ItemHeader>
                <ItemHeader header style={{ ...cssStyle.message }}>{t('rotation.message')}</ItemHeader>
                <ItemHeader header style={{ ...cssStyle.from }}>{t('rotation.from')}</ItemHeader>
              </HeaderHistoryRow>
              {results[page]?.map((history: SmsHistory, index) =>
                (
                  <HistoryRow key={history.id}>
                    <Item style={{ ...cssStyle.idContainer }}>{index + 1}</Item>
                    <Item style={{ ...cssStyle.date }}>{formatDate(history.t, dateFormats.dateTimeSec, locale)}</Item>
                    <Item style={{ ...cssStyle.message }}>{history.message}</Item>
                    <Item style={{ ...cssStyle.from }}>{history.from}</Item>
                  </HistoryRow>
                ))}
            </HistoryContainer>
            <DownloadHistory onClick={() =>
              $saveAsFile()}
            >
              {t('rotation.downloadSmsHistory')}
            </DownloadHistory>
          </>
        ) : (
          <EmptyMessage>{t('rotation.notFound')}</EmptyMessage>
        )}
      </Wrapper>
    </ScrollWrapper>
  );
};

interface TimePickerContentProps extends UIProps {
  width?: string;
}

export const TimePickerContent = styled.div<TimePickerContentProps>`
  display: flex;
  align-items: flex-end;

  width: ${(props) =>
    props.width || '100%'};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  box-sizing: border-box;

  .date_wrap {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};

    &:first-child {
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    }

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      flex-direction: column;
      align-items: start;
    }
  }

  .label {
    white-space: normal;
    margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  }
`;
