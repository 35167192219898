import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from '../core/config';
import { API_ENDPOINT } from '../core/api';
import { useAuthGuard } from '../hooks';
import { formatDate } from './formatter-utils';
import { dateFormats } from './constants';
import { DAY_IN_WEEK, MAX_WEEKS_LOGS } from '../constants/common';

export const saveToFile = (filename: string, data, format = 'text/plain') => {
  const element = document.createElement('a');
  element.setAttribute('href', `data:${format};charset=utf-8,${encodeURIComponent(data)}`);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const compareString = (a: string, b: string) =>
  a?.toLowerCase()?.trim().replace(' ', '') === b?.toLowerCase()?.trim().replace(' ', '');

export const debounceCall = debounce((callback) => {
  callback();
}, 50, {
  leading: false,
  trailing: true,
});

export const convertTrafficPoints = (points, divider = 1000000.0) => {
  const cv = (a) =>
    +(1.0 * (+a / divider)).toFixed(3);
  return points.map((a) =>
    ({
      ...a, in: cv(+a.in), out: cv(+a.out), v: cv(+a.v),
    }));
};

export const getGroupedTrafficPoints = (
  points,
  day_timestamp = undefined,
  groupingMinutesNumber = 10,
) => {
  let dayTimestamp = day_timestamp;
  if (points.length === 0) {
    return [];
  }
  if (dayTimestamp === undefined) {
    dayTimestamp = +points[0].t;
  }
  const from = +moment(+dayTimestamp).tz(DEFAULT_TIMEZONE).startOf('day');
  const to = +moment(+dayTimestamp).tz(DEFAULT_TIMEZONE).endOf('day');
  let t = from; const
    step = groupingMinutesNumber * 60000;
  const arr = []; const
    dataMap = {};
  const ge = (a) =>
    (a === undefined ? { in: 0, out: 0, v: 0 } : a);
  for (const i in points) {
    if (points.hasOwnProperty(i)) {
      const pt = points[i];
      const k = Math.floor((+pt.t - from) / step);
      const key = `${k * step}`;
      dataMap[key] = ge(dataMap[key]);
      dataMap[key].in = +dataMap[key].in + +pt.in;
      dataMap[key].out = +dataMap[key].out + +pt.out;
      dataMap[key].v = +dataMap[key].v + +pt.v;
    }
  }
  while (t < to) {
    const key = `${+t - +from}`;
    dataMap[key] = ge(dataMap[key]);
    arr.push({ t: +t, ...dataMap[key] });
    t = +t + +step;
  }
  return arr;
};

export const getEnMonthByTimestamp = (t) =>
  moment(t).tz(DEFAULT_TIMEZONE).format('MMMM');

export const generateRandomPassword = (length = 12) => {
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';
  const allCharacters = lowerCaseLetters + upperCaseLetters + digits;

  let password = '';

  password += lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)];
  password += upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];
  password += digits[Math.floor(Math.random() * digits.length)];

  for (let i = 3; i < length; i += 1) {
    password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
  }

  password = password.split('').sort(() =>
    Math.random() - 0.5).join('');

  return password;
};

export interface LogsWeek {
  from: number;
  to: number;
  label: string;
  index: number;
  download: (id: string, portsString?: string) => void
}

export const getLogsWeeks = (locale: string): LogsWeek[] => {
  const auth = useAuthGuard();

  return Array.from({ length: MAX_WEEKS_LOGS }).map((a, i) => {
    const from = +moment()
      .add((-i - 1) * DAY_IN_WEEK + 1, 'days')
      .startOf('day');
    const to = +moment()
      .add(-i * DAY_IN_WEEK, 'days')
      .endOf('day');

    return {
      from,
      to,
      label: `${formatDate(from, dateFormats.dateTime, locale)} - ${formatDate(to, dateFormats.dateTime, locale)}`,
      index: i,
      download: (id: string, portsString: string = '') => {
        const dateString = `${formatDate(from, dateFormats.fileDate, locale)}_${formatDate(to, dateFormats.fileDate, locale)}`;
        // eslint-disable-next-line max-len
        const url = `${API_ENDPOINT}/phone/${id}/logs-csv?from=${from}&to=${to}&name=${id}-${dateString}-logs.csv&${portsString}&token=${auth?.getToken}`;

        const link = document.createElement('a');
        link.href = url;
        link.download = '';
        link.click();
        link.remove();
      },
    };
  });
};
