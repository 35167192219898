import React, { useState, useEffect, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import moment from 'moment';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import {
  BasicButton,
  WrapperScreen,
  InputLabel,
} from '../styles/ui-controls';
import store from '../core/store/store';
import { loadUserActivityLogs } from '../core/store/actions/user';
import ScrollWrapper from '../components/common/ScrollWrapper';
import {
  TransactionsContainer,
  TransactionsRow,
  ScreenInfo,
  Error,
  Time,
  ButtonWrapper,
} from './styles';
import { dateFormats, BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../utils/constants';
import { formatDate } from '../utils/formatter-utils';
import { LoaderBlock } from '../components/edit-connection/StatisticsSettings/PhoneUptimePanel';
import { LoaderIcon } from '../components/common/LoaderIcon';
import { UIProps } from '../types';
import { TimePickerContent } from '../components/edit-connection/HistorySettings/SmsHistoryForm';

import { CustomeDateInputAtom } from '../components/atoms';

export interface ActivityLogsScreenProps {}

export const ActivityLogsScreen: FunctionComponent<ActivityLogsScreenProps> = () => {
  const { t, i18n } = useTranslation();

  const { activityLogs, id } = useTypedSelector(({ user }) =>
    user?.profile);
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    +new Date(moment().subtract(1, 'weeks').startOf('day').toISOString()),
  );
  const [endDate, setEndDate] = useState(+new Date());

  useEffect(() => {
    setLoading(true);
    store.dispatch(
      loadUserActivityLogs.request({
        userId: id,
        from: startDate,
        to: endDate,
        callback: () =>
          setLoading(false),
      }),
    );
  }, [id, startDate, endDate]);

  return (
    <WrapperScreen>
      {
        loading ? (
          <LoaderBlock>
            <LoaderIcon width="150px" height="150px" />
          </LoaderBlock>
        ) : (
          <>
            <Wrapper>
              <ScreenInfo
                marginTop="25px"
              >
                <div className="title">{t('profile.activityLogs')}</div>
              </ScreenInfo>

              <TimePickerContent width="none">
                <div className="date_wrap">
                  <InputLabel className="label">
                    {`${t(
                      'rotation.from',
                    )}:`}
                  </InputLabel>
                  <CustomeDateInputAtom
                    indicator
                    value={new Date(startDate)}
                    locale={i18n.language}
                    onChange={(e) =>
                      setStartDate(+moment(new Date(e)).startOf('day'))}
                  />
                </div>

                <div className="date_wrap">
                  <InputLabel className="label">{t('rotation.to')}</InputLabel>
                  <CustomeDateInputAtom
                    indicator
                    value={new Date(endDate)}
                    locale={i18n.language}
                    minDate={startDate}
                    onChange={(e) =>
                      setEndDate(+moment(new Date(e)).endOf('day'))}
                  />
                </div>
              </TimePickerContent>
            </Wrapper>

            {
              activityLogs?.length ? (
                <>
                  <Description>
                    {t('transaction.activity')}
                  </Description>

                  <ScrollWrapper>
                    <TransactionsContainer
                      minWidth="830px"
                      paddingRight="15px"
                    >
                      {activityLogs?.slice(0, 100 * count)?.map(({ timestamp, action, ip }) =>
                        (
                          <TransactionsRow key={timestamp + action}>
                            <div className="right">
                              <Time>
                                {formatDate(timestamp, dateFormats.dateTimeSec, i18n.language)}
                              </Time>
                              <div className="amount">
                                {t(`activityLogs.${action}`)}
                              </div>
                              <div className="ip">
                                {ip}
                              </div>
                            </div>
                          </TransactionsRow>
                        ))}
                    </TransactionsContainer>
                  </ScrollWrapper>

                  <ButtonWrapper>
                    {Math.ceil((activityLogs?.length ?? 0) / 100) > count && (
                      <BasicButton
                        size="small"
                        onClick={() =>
                          setCount(count + 1)}
                      >
                        {t('transaction.showMore')}
                      </BasicButton>
                    )}
                  </ButtonWrapper>
                </>
              ) : (
                <ScrollWrapper>
                  <TransactionsContainer
                    minWidth="830px"
                    paddingRight="15px"
                  >
                    <Error>
                      {t('activityLogs.error')}
                    </Error>
                  </TransactionsContainer>
                </ScrollWrapper>
              )
            }
          </>
        )
      }
    </WrapperScreen>
  );
};

const Description = styled.div`
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.midsmallZero};
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    margin-top: 50px;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    align-items: start;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    margin-top: 0;
  }
`;
