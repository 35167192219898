import React, {
  useState, useEffect, FunctionComponent, useRef,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';
import { MenuStructure, UIProps, PhonesStatusTabsType } from '../types';
import { DashboardSubItem } from './DashboardActionSubMenu';
import { useModalHook, useOnClickOutside } from '../hooks';

import iconArrowDown from '../assets/arrow_down.svg';
import iconSettings from '../assets/settings_icon.svg';
import iconListView from '../assets/menu_icon.svg';
import iconGridView from '../assets/grid_view.svg';
import iconAdd from '../assets/plus_icon.svg';
import iconMassAction from '../assets/mass-action-icon.svg';
import walletIcon from '../assets/menu_icon/wallet.svg';
import { ReactComponent as IconShowOnline } from '../assets/online.svg';
import { ReactComponent as IconShowOffline } from '../assets/offline.svg';
import GroupIcon from '../assets/group_icon2.svg';
import deselectAll from '../assets/phone_icons/deselect_all.svg';
import selectAll from '../assets/phone_icons/yes_icon_white.svg';
import settingsIcon from '../assets/settings_icon_slider.svg';

import { useTypedSelector } from '../core/store/selectors/type-selector';
import { ConnectionViewContext } from '../context/ConnectionViewContext';

import { analyticsClassnames } from '../utils/analytics-utils';
import { CloseBt } from './common/ModalComponent';
import { BREAKPOINT_MOBILE } from '../utils/constants';
import { useIsMobileHook } from '../hooks/useIsMobileHook';

interface CommonProps {
  hasPhones?: boolean;
  onSelectAll?: Function;
}

interface DashboardActionsMenuProps extends CommonProps {
  hasSelectedItems?: boolean;
  selectedPhones?: any;
  onOnlineOnly?: Function;
  onClear?: Function;
}

interface PhonesStatusTabsProps {
  title?: string;
  icon?: JSX.Element;
  tab: PhonesStatusTabsType;
  tooltip: string;
  btData: string;
  status: boolean | null;
}

interface MenuProps extends CommonProps {
  gridViewListOrTile: string;
  activePhonesStatusTab: PhonesStatusTabsType;
  allSelected: boolean;
  setAllSelected: Function;
  setExpandMenu: Function;
  setOnlyOnline: Function;
  setActivePhonesStatusTab: Function;
}

const Menu = ({
  gridViewListOrTile,
  activePhonesStatusTab,
  hasPhones,
  allSelected,
  onSelectAll = () => {},
  setAllSelected = () => {},
  setExpandMenu = () => {},
  setOnlyOnline = () => {},
  setActivePhonesStatusTab = () => {},
}: MenuProps) => {
  const modalOptions = useModalHook();
  const context = React.useContext(ConnectionViewContext);
  const { t } = useTranslation();
  const isMobile = useIsMobileHook();

  return (
    <MenuNav>
      <MenuNavItem
        onClick={() => {
          modalOptions.show('connection.group');
          setExpandMenu(false);
        }}
      >
        <ItemIcon src={GroupIcon} />
        <span>{t('connection.addGroup')}</span>
      </MenuNavItem>

      <MenuNavItem
        onClick={() => {
          context?.setGridView(!context?.gridView);
          setExpandMenu(false);
        }}
        data-tip={gridViewListOrTile}
      >
        {context?.gridView ? (
          <ItemIcon src={iconListView} />
        ) : (
          <ItemIcon src={iconGridView} />
        )}
        {
          isMobile && (
            <span>{gridViewListOrTile}</span>
          )
        }
      </MenuNavItem>

      {phonesStatusTabs.map(
        ({
          title, icon, tooltip, tab, btData, status,
        }) =>
          (
            <MenuNavItem
              key={btData}
              data-place="left"
              data-tip={t(tooltip)}
              onClick={() => {
                setOnlyOnline(status);
                setExpandMenu(false);
                setActivePhonesStatusTab(tab);
              }}
              className={
              activePhonesStatusTab === tab ? 'active-menu-item' : null
            }
            >
              {title && t(title)}
              {icon && icon}
              {
                isMobile && (
                  <span>{t(tooltip)}</span>
                )
              }
            </MenuNavItem>
          ),
      )}

      <MenuNavItem
        data-place="left"
        data-tip={`${t('toolTip.selectAll')}`}
        onClick={() => {
          if (hasPhones) {
            onSelectAll(allSelected);
            setAllSelected(!allSelected);
          }

          setExpandMenu(false);
        }}
      >
        <ItemIcon src={allSelected ? deselectAll : selectAll} />
        {
          isMobile && (
            <span>{t('toolTip.selectAll')}</span>
          )
        }
      </MenuNavItem>

      <MenuNavItem
        onClick={() => {
          modalOptions.show('dashboard.settings');
          setExpandMenu(false);
        }}
      >
        <ItemIcon src={iconSettings} />
        {
          isMobile && (
            <span>{t('toolTip.settings')}</span>
          )
        }
      </MenuNavItem>
    </MenuNav>
  );
};

const phonesStatusTabs: PhonesStatusTabsProps[] = [
  {
    title: 'stats.allPorts',
    tab: PhonesStatusTabsType.All,
    tooltip: 'toolTip.showAll',
    btData: 'show_all',
    status: null,
  },
  {
    icon: <IconShowOnline />,
    tab: PhonesStatusTabsType.Online,
    tooltip: 'toolTip.showOnlyOnline',
    btData: 'show_online_only',
    status: true,
  },
  {
    icon: <IconShowOffline />,
    tab: PhonesStatusTabsType.Offline,
    tooltip: 'toolTip.showOnlyOffline',
    btData: 'show_offline_only',
    status: false,
  },
];

export const DashboardActionsMenu: FunctionComponent<
DashboardActionsMenuProps
> = (props) => {
  const {
    hasPhones,
    hasSelectedItems = false,
    onOnlineOnly = () => {},
    onSelectAll = () => {},
    selectedPhones = null,
    onClear = () => {},
  } = props;

  const modalOptions = useModalHook();
  const supMenuClick = useRef(null);
  const { t } = useTranslation();
  const isMobile = useIsMobileHook();

  const [openAll] = useState(false);
  const [showFloatMenu, setShowFloatMenu] = useState(false);
  const [onlyOnline, setOnlyOnline] = useState(null);
  const phones = useTypedSelector(({ connections }) =>
    connections);
  const { profile } = useTypedSelector(({ user }) =>
    user);
  const [allSelected, setAllSelected] = useState(false);
  const [expandMobileMenu, setExpandMenu] = useState(false);
  const [activePhonesStatusTab, setActivePhonesStatusTab] = useState<PhonesStatusTabsType>(PhonesStatusTabsType.All);

  const context = React.useContext(ConnectionViewContext);

  const dashBoardMenu: MenuStructure[] = [
    {
      title: `${t('massActions.downloadCredentials')}`,
      onClick: () => {
        modalOptions.show('download-credentials', { selectedPhones, phones });
      },
    },
    {
      title: `${t('massActions.addToGroup')}`,
      onClick: () => {
        modalOptions.show('multi-group', { selectedPhones });
      },
    },
    {
      title: `${t('massActions.changeRotation')}`,
      onClick: () => {
        modalOptions.show('multi-rotation', { selectedPhones });
      },
    },
    {
      title: `${t('massActions.multiPayments')}`,
      onClick: () => {
        modalOptions.show('multi-payments', { selectedPhones });
      },
    },
    {
      title: `${t('massActions.multiRemove')}`,
      onClick: () => {
        modalOptions.show('multi-remove', { selectedPhones, onClear });
      },
    },
    {
      title: `${t('massActions.multiRename')}`,
      onClick: () => {
        modalOptions.show('multi-rename', { selectedPhones });
      },
    },
    {
      title: `${t('connection.changeIP')}`,
      onClick: () => {
        modalOptions.show('multi-changeIP', { selectedPhones });
      },
    },
    {
      title: `${t('massActions.shareTeam')}`,
      onClick: () => {
        modalOptions.show('multi-teamControl', { selectedPhones });
      },
    },
    // {
    //   title: `${t('massActions.globalDNS')}`,
    //   onClick: () => {
    //     modalOptions.show('multi-setupDNS', { selectedPhones });
    //   },
    // },
    {
      title: `${t('massActions.openVPNDNS')}`,
      onClick: () => {
        modalOptions.show('multi-setupOvpnDNS', { selectedPhones });
      },
    },
    {
      title: `${t('massActions.deviceRebootInterval')}`,
      onClick: () => {
        modalOptions.show('multi-setupRebootInterval', { selectedPhones });
      },
    },
    {
      title: `${t('proSettings.rebootDevice')}`,
      onClick: () => {
        modalOptions.show('multi-rebootDevice', { selectedPhones });
      },
    },
    !profile?.isLogsForbidden
      ? {
        title: `${t('logs.download')}`,
        onClick: () => {
          modalOptions.show('multi-downloadLogs', { selectedPhones });
        },
      }
      : null,
    {
      title: `${t('massActions.alertsTelegramBot')}`,
      onClick: () => {
        modalOptions.show('multi-setupNotificationsTg', { selectedPhones });
      },
    },
  ];

  useOnClickOutside(supMenuClick, () =>
    setShowFloatMenu(false));

  useEffect(() => {
    if (hasSelectedItems != null) {
      setShowFloatMenu(false);
    }
  }, [hasSelectedItems]);

  useEffect(() => {
    onOnlineOnly(onlyOnline);
  }, [onlyOnline]);

  const gridViewListOrTile = context?.gridView
    ? t('toolTip.list')
    : t('toolTip.tile');

  const menuProps = {
    gridViewListOrTile,
    activePhonesStatusTab,
    hasPhones,
    allSelected,
    onSelectAll,
    setAllSelected,
    setExpandMenu,
    setOnlyOnline,
    setActivePhonesStatusTab,
  };

  return (
    <Wrapper>
      <MenuWrapper>
        {hasSelectedItems ? (
          <ConnectionItem
            onClick={() => {
              setShowFloatMenu(!showFloatMenu);
            }}
            ref={supMenuClick}
          >
            <ItemIcon src={iconMassAction} />
            <span className="actions">{t('connection.bulkActions')}</span>
            <span className="counter">{selectedPhones.length}</span>
            <ItemIcon src={iconArrowDown} rotation90={showFloatMenu} />
            {showFloatMenu ? (
              <SubmenuContainer openAll={openAll}>
                {dashBoardMenu?.map(
                  (menu) =>
                    menu && <DashboardSubItem menu={menu} key={menu.title} />,
                )}
              </SubmenuContainer>
            ) : null}
          </ConnectionItem>
        ) : (
          <ConnectionItem
            className={analyticsClassnames.createConnection}
            onClick={() => {
              modalOptions.show('phone.new');
            }}
          >
            <ItemIcon src={iconAdd} />
            <span>{t('connection.createConnection')}</span>
          </ConnectionItem>
        )}

        {
          isMobile && (
            <>
              <MobileMenuButton onClick={() =>
                modalOptions.show('balance.topUp')}
              >
                <ItemIcon src={walletIcon} />
              </MobileMenuButton>

              <MobileMenuButton onClick={() =>
                setExpandMenu(true)}
              >
                <ItemIcon src={settingsIcon} />
              </MobileMenuButton>
            </>
          )
        }

        {
          !isMobile ? (
            <MenuNavWrapperDesktop>
              <Menu {...menuProps} />
            </MenuNavWrapperDesktop>
          ) : isMobile && expandMobileMenu ? (
            <MenuNavWrapperMobile>
              <MenuClose>
                <CloseBt
                  onClick={() =>
                    setExpandMenu(false)}
                />
              </MenuClose>
              <Menu {...menuProps} />
            </MenuNavWrapperMobile>
          ) : null
        }
      </MenuWrapper>

      <ReactTooltip />
    </Wrapper>
  );
};

interface ItemIconProp {
  src?: string;
  rotation90?: boolean;
}

const ItemIcon = styled.div<ItemIconProp>`
  width: 16px;
  height: 16px;
  background-image: url(${(props: ItemIconProp) =>
    props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: ${(prop: ItemIconProp) =>
    (prop.rotation90 ? ' rotate(180deg)' : ' rotate(0)')};
`;

const Wrapper = styled.div``;

interface SubmenuContainerProps extends UIProps {
  openAll?: boolean;
}

const SubmenuContainer = styled.div<SubmenuContainerProps>`
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: start;
  color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.white};
  width: 100%;
  position: absolute;
  top: 42px;
  right: 0;
  background-color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
  box-sizing: border-box;
  z-index: 10;
  padding: 8px 0 8px 0;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  transition: 0.2s;
  overflow: hidden;

  .sub-item-value {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroMedium};

    :hover {
      background-color: ${(prop: UIProps) =>
    prop.theme.colors.brightBlue};
    }
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ConnectionItem = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroMedium};
  height: 38px;
  box-sizing: border-box;
  z-index: 3;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroSmaller};
  }

  background-color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.leftAnglesSmaller};

  cursor: pointer;

  &:hover:before {
    content: '';
    width: 100%;
    height: 130%;
    background-color: rgb(255, 255, 255, 0.1);
    position: absolute;
    top: -16%;
    left: 0;
  }

  & > span {
    margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }

  & > span.actions {
    margin:  ${(props: UIProps) =>
    props.theme.sizes.padding.zeroSmaller};
  }

  & > span.counter {
    margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};
    background-color: white;
    color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroSmall};
    font-weight: 600;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex: 1 1;
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    justify-content: center;
  }
`;

const MenuNavWrapperDesktop = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  background-color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.rightAnglesSmaller};
  box-sizing: border-box;
`;

const MenuNavWrapperMobile = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100vh;
  background-color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
  display: flex;
  flex-direction: column;
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroMedium};
`;

const MenuNav = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    flex-direction: column;
  }
`;

const MenuNavItem = styled.li`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;

  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.zeroMedium};
  border-right: 1px solid rgb(255, 255, 255);
  box-sizing: border-box;

  color: ${(props: UIProps) =>
    props.theme.colors.white};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
  transition: 0.3s;
  cursor: pointer;

  &.active-menu-item {
    background-color: ${(prop: UIProps) =>
    prop.theme.colors.greenMud};
  }

  &:hover:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255, 0.1);
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    padding: 30px 20px;

    border-right: none;
    border-left: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    &:hover:before {
      height: 100%;
      top: 0;
    }
  }

  &:first-child {
    border-left: 1px solid rgb(255, 255, 255);

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      border-left: none;
    }
  }

  &:last-child {
    border-right: none;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      border-bottom: none;
    }
  }

  & > span {
    margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }
`;

const MobileMenuButton = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  background-color: ${(prop: SubmenuContainerProps) =>
    prop.theme.colors.deepBlue};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  cursor: pointer;

  &:hover:before {
    content: '';
    width: 100%;
    height: 130%;
    background-color: rgb(255, 255, 255, 0.1);
    position: absolute;
    top: -16%;
    left: 0;
  }
`;

const MenuClose = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px;
  cursor: pointer;

  div.icon-close > div {
    width: 32px;
    height: 32px;
  }
`;
