import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BasicButton } from '../../styles/ui-controls';
import warning from '../../assets/warning_icon.svg';
import calendar from '../../assets/timetable-icon.svg';
import { BREAKPOINT_MOBILE } from '../../utils/constants';
import { useIsMobileHook } from '../../hooks/useIsMobileHook';
import { UIProps } from '../../types';

interface ContainerProps {
  width: string;
  padding: string;
  textAlign?: string;
}

const Container = styled.div<ContainerProps>`
  width: ${(props) =>
    props.width};
  padding: ${(props) =>
    props.padding};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  .skip-text {
    display: flex;
    flex-direction: column;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.smallMedium};
    }
  }

  .recommendation {
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};
    text-align: ${(props) =>
    props.textAlign};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.small};
    }
  }

  .skip-title {
    ${(props: UIProps) =>
    props.theme.sizes.font.largeMedium};
  }
`;

interface SkipBlockProps {
  imgSize: string;
}

const SkipBlock = styled.div<SkipBlockProps>`
  padding: 24px;
  display: flex;
  gap: 40px;
  align-items: center;
  background-color: ${(props: UIProps) =>
    props.theme.colors.corol};
  border: 1px solid #ff0000;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};

  img {
    width: ${(props) =>
    props.imgSize};
    height: ${(props) =>
    props.imgSize};
  }

  .button {
    padding: 0 25px;
    height: 48px;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    text-align: center;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  }
`;

export interface ApiWarningBlockProps extends ContainerProps, SkipBlockProps {
  components: JSX.Element;
  isWarning: boolean;
  onClick?: Function;
}

export const ApiWarningBlock: FunctionComponent<ApiWarningBlockProps> = ({
  width,
  padding,
  imgSize,
  textAlign = 'center',
  isWarning,
  components,
  onClick,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobileHook();

  return (
    <Container
      width={width}
      padding={padding}
      textAlign={textAlign}
    >
      <SkipBlock imgSize={imgSize}>
        {
          !isMobile && (
            <img src={isWarning ? warning : calendar} alt="" />
          )
        }
        <div>
          {components}
          {
            onClick
            && (
            <BasicButton
              className="button"
              color="warning"
              onClick={() =>
                onClick()}
            >
              {t('changePswAndApiKey.skip')}
            </BasicButton>
            )
          }
        </div>
      </SkipBlock>
    </Container>
  );
};
