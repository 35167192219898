/* eslint-disable @typescript-eslint/no-unused-vars */
import mixpanel from 'mixpanel-browser';

mixpanel.init('4ed9076bd528a0bcdef0a6bd07b73197', { batch_requests: true, debug: true });

// @ts-nocheck
export default class AnalyticsUtils {
  static initDashboardUserId(userId, userEmail, telegram, createdDate, userName, lang, systemLang) {
    try {
      // google
      if (window == null || !window.hasOwnProperty('gtag')) return;
      window?.gtag('config', 'G-KTTWDH52NH', {
        user_id: userId,
      });
    } catch (e) {
      console.error('Error:> [analytics-utils.ts] Error :=', e);
    }
  }
}

export const analyticsClassnames = {
  wallet: 'add_money_with_wallet',
  topUpBalance: 'top_up_balance',
  topUpBalanceMultipayment: 'top_up_balance_multipayment',
  pushPayment: 'push_stripe',
  pushCryptoPayment: 'push_capitalist',
  pushTariff: 'push_tariff',
  extendCurrentTariff: 'extend_current_tariff',
  switchTariffPrefix: 'switch',
  switchTariffPostfix: 'tariff',
  paymentSuccess: 'payment_success',
  createConnection: 'create_connection',
};

export const analyticsClassnamePayments = {
  cryptoCom: 'crypto.com',
  btc: 'btc',
  trc20usdt: 'trc20usdt',
  capitalist: 'capitalist',
  visa: 'visa',
  mastercard: 'mastercard',
};
