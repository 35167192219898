import React, {
  useState, useEffect, FunctionComponent, useMemo,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import 'react-toastify/dist/ReactToastify.css';

import Highlighter from 'react-highlight-words';
import store from '../core/store/store';
import { Phone, RealTimeStatus } from '../types/phones';
import { useModalHook } from '../hooks';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import {
  Connection, PlansType, UIProps,
} from '../types';
import {
  debounceCall,
  emptyHolder,
  getNumEditing,
  showNotification,
  textShorter,
  $downloadConfig,
  formatDate,
  exportCredentials,
  getRelevantVersion,
} from '../utils';
import { BatteryStatus } from './atoms/battery-status';
import { changePhoneIpAddress } from '../core/store/actions/connections';

import iconApproveActive from '../assets/checkbox.svg';
import iconApprove from '../assets/phone_icons/yes_icon.svg';
import iconExpand from '../assets/expand_icon.svg';
import iconCopy from '../assets/coppy_icon.svg';
import iconDownload from '../assets/icon_download.svg';
import { ReactComponent as IconSettings } from '../assets/phone_icons/setting_icon.svg';
import { ReactComponent as IconIpChange } from '../assets/ipchange-icon.svg';
import { DashboardConfig } from '../types/dashboard';
import { setDashboardConfig } from '../core/store/actions/app';
import iconWarning from '../assets/Warning/Triangle_Warning.svg';
import { AlertTrafficsComponent } from './edit-connection/AlertTrafficsComponent';
import { ActionBt } from './edit-connection/BigDaddyPro/openvpn/OpenVpnConnections';
import { dateFormats, BREAKPOINT_MOBILE } from '../utils/constants';
import theme from '../styles/ApplicationTheme';
import { TrafficComponent } from './TrafficComponent';
import { FlagComponent } from './common/FlagComponent';

import warningIcon from '../assets/warning_icon.svg';
import ipWarningIcon from '../assets/ip_warning_icon.svg';

interface PhoneItemRowComponentProps {
  phone: Phone;
  index: number;
  isSelected: boolean;
  onSelected: Function;
  realTimeStatus: RealTimeStatus;
  needAppUpdate: boolean;
  credTemplate: boolean;
  appDashBoardConf?: DashboardConfig;
  expanded?: boolean;
  searchKeys?: string[] | null | string;
}

export const PhoneItemRowComponent: FunctionComponent<
PhoneItemRowComponentProps
> = (props) => {
  const {
    phone,
    searchKeys = [],
    index,
    realTimeStatus,
    onSelected,
    isSelected,
    needAppUpdate,
    credTemplate,
    expanded = false,
    appDashBoardConf,
  } = props;
  const modalOptions = useModalHook();
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  const servers = useTypedSelector(
    ({ servers }) =>
      servers?.userServers,
  );
  const proxyServer = servers.find((i) =>
    i.id === phone?.slaveServerId);

  const expDate = moment(phone?.expDate);
  const isExpired = moment().isSameOrAfter(expDate);
  const isExpiredProxyPassword = phone?.connections?.some((item) =>
    moment(item?.passwordLastUpdateTimestamp).add(90, 'days').valueOf() <= +new Date());
  const isExpiredProxyIp = proxyServer
    ? phone?.connections?.some((item) =>
      item?.address !== proxyServer.ip)
    : false;

  const plans = useTypedSelector(
    ({ plans }) =>
      plans?.data,
  );
  const currentPlan = plans.find((plan) =>
    plan?.id === phone?.activePlans[0]?.id);
  const maxProxies = currentPlan?.max_proxies_per_connection || '-';
  const noExpirationTimestampProxies = phone?.connections?.filter((item) =>
    !item?.expirationTimestamp)?.length;
  const notExpiredProxies = phone?.connections?.filter((item) =>
    Date.now() < item?.expirationTimestamp)?.length;
  const expiredProxies = phone?.connections?.filter((item) =>
    item?.expirationTimestamp && Date.now() > item?.expirationTimestamp)?.length;
  const serverGeo = phone?.serverGeo;

  const {
    copySettings,
    isConnectionIdColumn,
    isOvpnConfigsColumn,
    isRotationColumn,
    isExternalIpColumn,
    isTariffColumn,
    isExpirationDatesColumn,
    isUsageColumn,
    isServerGeoColumn,
    isTrafficColumn,
  } = useTypedSelector(
    ({ app }) =>
      app.dashboard_config?.configs,
  );

  const [updatingIp, setUpdatingIp] = useState(false);
  const [expandStatus, setExpandStatus] = useState(expanded);
  const [willExpiredTomorrow, setWillExpiredTomorrow] = useState<any>();
  const [isNumericFormatIP, setIsNumericFormatIP] = useState<any>();
  const [previousIpParams, setPreviousIpParams] = useState<any>({
    timestamp: null,
    ip: null,
  });

  const currentPhoneIP = useMemo(
    () =>
      (phone?.onlineStatus?.online
        ? phone?.onlineStatus?.ip4 != null && phone?.onlineStatus?.ip4 !== ''
          ? phone?.onlineStatus.ip4
          : phone?.onlineStatus.ip6
        : null),
    [phone?.onlineStatus],
  );

  const alert = phone?.alerts?.[0];

  useEffect(() => {
    setExpandStatus(expanded);
  }, [expanded]);

  useEffect(() => {
    if (
      updatingIp
      && previousIpParams?.ip != null
      && previousIpParams?.ip !== currentPhoneIP
    ) {
      showNotification(
        <>
          {t('notification.newIP', {
            newIP: `${currentPhoneIP || '-'}`,
            phoneName: `${phone?.name}`,
            prevIP: `${previousIpParams?.ip}`,
          })}
        </>,
      );
      setUpdatingIp(false);
    }
  }, [realTimeStatus]);

  useEffect(() => {
    if (appDashBoardConf != null && phone != null) {
      const dashboardConf: DashboardConfig = appDashBoardConf as DashboardConfig;
      if (dashboardConf.configs.expandStatus != null) {
        const result = dashboardConf.configs.expandStatus?.find(
          (i) =>
            i?.phoneId === phone?.id,
        );
        setExpandStatus(result?.status);
      } else {
        setExpandStatus(false);
      }
    }
  }, [appDashBoardConf, phone]);

  useEffect(() => {
    if (appDashBoardConf != null) {
      setIsNumericFormatIP(appDashBoardConf.configs.isNumericFormatIP);
    }
  }, [appDashBoardConf]);

  useEffect(() => {
    const days = appDashBoardConf != null
      && appDashBoardConf?.configs?.tariffExpNotification != null
      ? appDashBoardConf.configs.tariffExpNotification / 24
      : 1;

    setWillExpiredTomorrow(
      moment().isSameOrAfter(
        moment(phone?.activePlans[0]?.expirationTimestamp).subtract(
          days,
          'days',
        ),
      ),
    );
  }, [appDashBoardConf]);

  const renderConnections = (connection: Connection, index) => {
    if (connection == null) {
      return null;
    }

    const connectionStr = renderCredentials(
      connection.listen_service.toLowerCase(),
      isNumericFormatIP
        ? connection?.address
        : connection?.hostname || connection?.address,
      connection.port,
      connection.login,
      connection.password,
      connection?.auth_type,
    );

    return (
      <ConnectionsWrapper>
        <ConnectionsItem key={index}>
          <div
            data-place="left"
            data-tip={`${t('toolTip.copy')}`}
          >
            <ConnectionsCredCopy
              onClick={async () => {
                await exportCredentials({
                  format: copySettings.format,
                  phones: [phone],
                  copySettings,
                  isCopy: true,
                });
                showNotification(`${t('notification.dataCopiedSuccessfully')}`);
              }}
            />
          </div>
          <span
            data-place="left"
            data-tip={`${
              connection?.description != null
              && connection?.description !== ''
              && connection?.description !== 'this proxy was created automatically'
                ? connection?.description
                : ''
            }`}
          >
            <Highlighter
              highlightClassName="highlight-item"
              searchWords={searchKeys as any}
              autoEscape
              textToHighlight={connectionStr}
            />
          </span>
        </ConnectionsItem>
        {renderExpirationDateBlock(connection.expirationTimestamp)}
      </ConnectionsWrapper>
    );
  };

  const renderOVPNConfigs = (openVPN, phoneId) => {
    const expirationTimestamp = openVPN?.expiredTimestamp;

    if (openVPN == null) {
      return null;
    }

    return (
      <OVPNConfigWrapper key={openVPN.id}>
        <OVPNConfigInnerWrapper>
          <div
            data-place="left"
            data-tip={`${t('toolTip.downloadOVPNConfig')}`}
          >
            <ActionBt
              icon={iconDownload}
              onClick={() => {
                $downloadConfig(openVPN?.id, openVPN?.name, phoneId, isNumericFormatIP);
              }}
            />
          </div>
          <span style={{ maxWidth: '150px' }}>
            <Highlighter
              highlightClassName="highlight-item"
              searchWords={searchKeys as any}
              autoEscape
              textToHighlight={openVPN.name}
            />
          </span>
        </OVPNConfigInnerWrapper>
        {renderExpirationDateBlock(expirationTimestamp)}
      </OVPNConfigWrapper>
    );
  };

  const renderExpirationDateBlock = (expirationTimestamp) =>
    (
      <>
        {expirationTimestamp && (
        <ExpirationDateBlock
          expirated={Date.now() > expirationTimestamp}
        >
          {t('tariff.expires', {
            date: `${formatDate(expirationTimestamp, dateFormats.dateGmt, locale)}`,
          })}
        </ExpirationDateBlock>
        )}
      </>
    );

  const changeIp = () => {
    store.dispatch(
      changePhoneIpAddress({ phoneId: phone.id, timestamp: phone.timestamp }),
    );
    setUpdatingIp(true);
    setPreviousIpParams({
      timestamp: +new Date(),
      ip: currentPhoneIP,
    });
    showNotification(
      <>
        {t('notification.changeIpRequest')}
        {' '}
        <span style={{ fontWeight: 'bold', padding: '0 10px' }}>
          {' '}
          {phone?.name}
          {' '}
        </span>
      </>,
    );

    debounceCall(() => {
      setTimeout(() => {
        setUpdatingIp(false);
      }, 30000);
    });
  };

  const renderTariffExpData = () =>
    (isExpired ? (
      <RemoveDateHilight>
        <div>
          {t('tariff.willBeRemoved', {
            date: `${formatDate(expDate.add(10, 'days'), dateFormats.dateTime, locale)}`,
          })}
        </div>
      </RemoveDateHilight>
    ) : willExpiredTomorrow ? (
      <RemoveDateHilight>
        <div className="expired-highlight">{t('tariff.willExpires')}</div>
        <div>
          {t('tariff.expired', {
            date: `${formatDate(expDate, dateFormats.dateTime, locale)}`,
          })}
        </div>
      </RemoveDateHilight>
    ) : (
      `${t('tariff.expires', {
        date: `${formatDate(expDate, dateFormats.dateTime, locale)}`,
      })}`
    ));

  const renderCredentials = (type, ip, port, login, password, authType) =>
    (authType === 'userpass'
      ? !credTemplate
        ? `${type}:${ip}:${port}:${login}:${password}`
        : `${type}|${ip}|${port}|${login}|${password}`
      : !credTemplate
        ? `${type}:${ip}:${port}`
        : `${type}|${ip}|${port}`);

  const setExpand = () => {
    const dashboardConf: DashboardConfig = appDashBoardConf as DashboardConfig;

    let expandConf = dashboardConf.configs.expandStatus == null
      ? []
      : dashboardConf.configs?.expandStatus;

    if (expandConf != null && expandConf.length) {
      const hasValue = expandConf.find((i) =>
        i.phoneId === phone?.id);
      if (hasValue != null) {
        expandConf = expandConf?.map((i) =>
          (i.phoneId === phone?.id ? { ...i, status: !expandStatus } : i));
      } else {
        expandConf.push({ status: !expandStatus, phoneId: phone?.id });
      }
    } else {
      expandConf.push({ status: !expandStatus, phoneId: phone?.id });
    }

    setExpandStatus(!expandStatus);
    store.dispatch(
      setDashboardConfig.request({
        configs: {
          ...dashboardConf.configs,
          expandStatus: expandConf,
        },
      }),
    );
  };

  const renderDescriptionContent = (number, description) => {
    const hasNumber = number != null && number !== '';
    const hasDescription = description != null && description !== '';
    if (hasDescription && hasNumber) {
      return `${number}, ${description}`;
    }
    if (hasDescription && !hasNumber) {
      return description;
    }
    if (!hasDescription && hasNumber) {
      return number;
    }
    return '-';
  };

  const renderPhoneDescriptionLabel = (
    deviceModel,
    deviceManufacturer,
    version,
    appVersion,
  ) => {
    const deviceLabel = emptyHolder(deviceModel, '');
    const manufactureLabel = emptyHolder(deviceManufacturer, '');
    const versionLabel = emptyHolder(version, '');
    const appVersionLabel = emptyHolder(appVersion);
    const relevantVersion = getRelevantVersion(appVersion);

    return (
      <div className="phone-specification">
        {versionLabel != null && versionLabel !== ''
          ? (
            <>
              {deviceLabel}
              {' '}
              {manufactureLabel}
              {' '}
              (Android
              {' '}
              {versionLabel}
              )
              <br />
              iProxy
              <AppVersion
                relevantVersion={relevantVersion}
                color="inherit"
              >
                {' '}
                v
                {appVersionLabel}
              </AppVersion>
            </>
          )
          : null}
      </div>
    );
  };

  const isEmptyNetworkType = phone?.deviceMetrics?.networkType?.toLowerCase() == null
    || phone?.deviceMetrics?.networkType?.toLowerCase() === 'none';

  return (
    <DataRow
      selected={isSelected}
      key={index}
      hasIpIssue={phone?.ipNotChnagedForSomeTime}
      online={phone?.onlineStatus?.online}
      expired={isExpired}
      willExpire={willExpiredTomorrow}
    >
      <td>
        <CellContent>
          <ExpandItem
            onClick={() =>
              setExpand()}
            active={expandStatus}
          />
        </CellContent>
      </td>
      <td style={{ maxWidth: '250px' }}>
        <CellContent>
          <PhoneInfoContainer>
            <PhoneName
              online={phone?.onlineStatus?.online}
              onClick={() =>
                modalOptions.show('phone.edit', { phone })}
            >
              <Highlighter
                highlightClassName="highlight-item"
                searchWords={searchKeys as any}
                autoEscape
                textToHighlight={textShorter(
                  !expandStatus && phone?.description != null
                    ? `${phone.name} - ${phone.description}`
                    : `${phone.name}`,
                  60,
                )}
              />
            </PhoneName>

            {alert && phone.activePlans[0]?.id === PlansType.BigDaddyPro && (
              <TrafficLimits>
                {t('bot.trafficLimits')}
                {' '}
                <AlertTrafficsComponent alert={alert} />
              </TrafficLimits>
            )}

            <PhoneNameSubInfo>
              <PhoneNameSubInfoWrapper>
                {phone?.deviceModel != null && phone?.isRoot ? (
                  <InfoLabel color="greenMud">
                    <span
                      className="tooltip-action-container"
                      data-place="right"
                      data-tip={t('toolTip.hasRoot')}
                    >
                      ROOT
                    </span>
                  </InfoLabel>
                ) : null}

                {phone?.deviceOwnerModeEnabled ? (
                  <InfoLabel color="greenMud">
                    <span
                      className="tooltip-action-container"
                      data-place="right"
                      data-tip={t('toolTip.ownerMode')}
                    >
                      OWNER
                    </span>
                  </InfoLabel>
                ) : null}

                {phone?.voiceAssistConfigured ? (
                  <InfoLabel color="greenMud">
                    <span
                      className="tooltip-action-container"
                      data-place="left"
                      data-tip={`${t('toolTip.voice')}`}
                    >
                      VOICE
                    </span>
                  </InfoLabel>
                ) : null}

                {phone?.wifiSplitEnabled ? (
                  <InfoLabel color="greenMud">
                    <span
                      className="tooltip-action-container"
                      data-place="left"
                      data-tip="Wifi split"
                    >
                      SPLIT
                    </span>
                  </InfoLabel>
                ) : null}
              </PhoneNameSubInfoWrapper>

              <PhoneNameSubInfoWrapper>
                {phone?.onlineStatus?.online ? (
                  <InfoLabel color={isEmptyNetworkType ? 'warning' : 'greenMud'}>
                    <span
                      className="tooltip-action-container"
                      data-place="left"
                      data-tip={`${t('toolTip.signalType')}`}
                    >
                      {isEmptyNetworkType
                        ? '-'
                        : phone?.deviceMetrics?.networkType}
                    </span>
                  </InfoLabel>
                ) : null}

                {phone?.onlineStatus?.online ? (
                  <BatteryStatus value={phone?.deviceMetrics?.batteryLevel} />
                ) : null}
              </PhoneNameSubInfoWrapper>
            </PhoneNameSubInfo>
            <PhoneInfo active={expandStatus}>
              <div className="phone-number">
                <Highlighter
                  highlightClassName="highlight-item"
                  searchWords={searchKeys as any}
                  autoEscape
                  textToHighlight={renderDescriptionContent(
                    null,
                    phone?.description,
                  )}
                />
                {/* {_renderDescriptionContent( */}
                {/*    null, */}
                {/*    phone?.description */}
                {/* )} */}
              </div>
              {renderPhoneDescriptionLabel(
                phone?.deviceModel,
                phone?.deviceManufacturer,
                phone.version,
                phone?.appVersion,
              )}
            </PhoneInfo>
          </PhoneInfoContainer>
        </CellContent>
      </td>
      {
        isServerGeoColumn && (
        <td>
          <CellContent className="server_geo">
            {
              serverGeo && (
                <Server>
                  <FlagComponent
                    serverGeo={serverGeo}
                    width="25px"
                    height="25px"
                  />
                </Server>
              )
            }
          </CellContent>
        </td>
        )
      }
      {
        isUsageColumn && (
          <td>
            <CellContent className="usage">
              <span
                className="tooltip-action-container"
                data-place="left"
                data-tip={`${t('toolTip.createdProxies')}`}
              >
                {`${phone.connectionsLength}`}
              </span>
              <span>&nbsp;/&nbsp;</span>
              <span
                className="tooltip-action-container"
                data-place="left"
                data-tip={`${t('toolTip.maxProxiesByTariff')}`}
              >
                {`${maxProxies}`}
              </span>
            </CellContent>
            <CellContent className="usage">
              <span
                className="tooltip-action-container"
                data-place="left"
                data-tip={`${t('toolTip.noExpTimestampProxies')}`}
              >
                {`${noExpirationTimestampProxies}`}
              </span>
              |
              <span
                className="tooltip-action-container"
                data-place="left"
                data-tip={`${t('toolTip.notExpiredProxies')}`}
                style={{ color: theme.colors.green }}
              >
                {`${notExpiredProxies}`}
              </span>
              |
              <span
                className="tooltip-action-container"
                data-place="left"
                data-tip={`${t('toolTip.expiredProxies')}`}
                style={{ color: theme.colors.warning }}
              >
                {`${expiredProxies}`}
              </span>
            </CellContent>
          </td>
        )
      }
      <td>
        <CellContent>
          <ConnectionsContainer>
            {expandStatus
              ? phone?.connections?.map((connection: Connection, index) =>
                renderConnections(connection, index))
              : renderConnections(
                phone?.connections != null ? phone?.connections[0] : null,
                0,
              )}
          </ConnectionsContainer>
        </CellContent>
      </td>
      {
        isOvpnConfigsColumn && (
          <td className="ovpn-config">
            <CellContent>
              <ConnectionsContainer>
                {expandStatus
                  ? phone?.openVPN?.map((config) =>
                    renderOVPNConfigs(config, phone?.id))
                  : renderOVPNConfigs(
                    phone?.openVPN != null ? phone?.openVPN[0] : null,
                    phone?.id,
                  )}
              </ConnectionsContainer>
            </CellContent>
          </td>
        )
      }
      {
        isRotationColumn && (
          <td>
            <CellContent>
              {phone?.ipChangeEnabled
                ? `${phone.ipChangeMinutesInterval} ${t('rotation.min')}`
                : `${t('rotation.off')}`}
            </CellContent>
          </td>
        )
      }
      {
        isExternalIpColumn && (
          <td>
            <CellContent>
              <PhoneIpContainer>
                <div className="phone-ip">
                  {phone?.onlineStatus?.online && phone?.ipNotChnagedForSomeTime ? (
                    <WarningIpIcon
                      className="tooltip-action-container"
                      data-place="left"
                      data-tip={`${t('toolTip.ipDidnotChanged', {
                        time: appDashBoardConf?.configs?.ipNotChangeTime,
                        lastTime: formatDate(phone?.lastIpChangeTimestamp, dateFormats.mins, locale),
                        unit: getNumEditing(
                          appDashBoardConf?.configs?.ipNotChangeTime,
                          i18n.language,
                          'minutes',
                        ),
                      })}`}
                    />
                  ) : null}
                  {currentPhoneIP || '-'}
                </div>
                {phone?.onlineStatus?.online ? (
                  <div
                    className="phone-change-ip"
                    onClick={() =>
                      changeIp()}
                  >
                    {updatingIp ? (
                      <IpChangeProgress />
                    ) : (
                      <span>{t('connection.changeIP')}</span>
                    )}
                  </div>
                ) : null}
                {phone?.onlineStatus?.online && phone?.ipDublicates?.length ? (
                  <InfoLabel color="greenMud">
                    <div
                      className="tooltip-action-container"
                      data-place="left"
                      data-tip={`${t('toolTip.notUniqIp')} ${
                        phone?.ipDublicates?.length
                      }`}
                    >
                      RPT
                    </div>
                  </InfoLabel>
                ) : null}
              </PhoneIpContainer>
            </CellContent>
          </td>
        )
      }
      {
        isTariffColumn && (
          <td>
            <CellContent>
              <PhoneTariffContainer>
                <div className="phone-tariff">
                  {
                    isExpired || willExpiredTomorrow ? null : (
                      <span
                        className="tariff"
                        onClick={() =>
                          modalOptions.show('phone.edit', {
                            phone,
                            menuKey: 'tariff',
                          })}
                      >
                        {phone?.tariffName}
                      </span>
                    )
                  }
                </div>
              </PhoneTariffContainer>
            </CellContent>
            {
              isExpired
              && (
              <RemoveDateHilight>
                <div className="remove-highlight">{t('tariff.removed')}</div>
              </RemoveDateHilight>
              )
            }
          </td>
        )
      }
      {
        isExpirationDatesColumn && (
          <td>
            <CellContent>
              <PhoneTariffContainer>
                <div className="phone-tariff">
                  {renderTariffExpData()}
                </div>
                <div className="phone-extensions">
                  {phone?.prolongationEnabled === true
                  || phone?.prolongationEnabled === undefined ? (
                    <InfoLabel color="greenMud">
                      <span
                        className="tooltip-action-container"
                        data-place="left"
                        data-tip={`${t('toolTip.autoRenewal')}`}
                      >
                        AUTO
                      </span>
                    </InfoLabel>
                    ) : null}

                  {!phone?.isRoot && !phone?.deviceOwnerModeEnabled
                  && phone?.onlineStatus?.online && !isEmpty(phone?.macrosUrl) ? (
                    <InfoLabel color="greenMud">
                      <span
                        className="tooltip-action-container"
                        data-place="left"
                        data-tip={t('toolTip.hasMD')}
                      >
                        MD
                      </span>
                    </InfoLabel>
                    ) : null}

                  {needAppUpdate && phone?.onlineStatus?.online ? (
                    <InfoLabel
                      onClick={() => {
                        if (needAppUpdate) {
                          modalOptions.show('phone.update.app', {
                            phone,
                          });
                        }
                      }}
                      color={needAppUpdate ? 'deepBlue' : 'warning'}
                      isAction
                    >
                      <span
                        className="tooltip-action-container-action"
                        data-delay-hide={3000}
                        data-place="left"
                        data-tip={
                          needAppUpdate
                            ? t('toolTip.updateAvailable')
                            : t('toolTip.updateInstalled')
                        }
                      >
                        UPD
                      </span>
                    </InfoLabel>
                  ) : null}
                </div>
              </PhoneTariffContainer>
            </CellContent>
          </td>
        )
      }
      {
        isConnectionIdColumn && (
          <td>
            <CellContent>
              <Highlighter
                highlightClassName="highlight-item"
                searchWords={searchKeys as any}
                autoEscape
                textToHighlight={phone?.id}
              />
            </CellContent>
          </td>
        )
      }
      {isTrafficColumn && (
        <td>
          <CellContent className="traffic_column">
            <TrafficComponent
              phone={phone}
            />
          </CellContent>
        </td>
      )}
      <td>
        <CellContent className="last_td">
          <PhoneControlsContainer>
            <div className="actions">
              <div
                data-place="left"
                data-tip={`${t('toolTip.select')}`}
              >
                <IconBt
                  icons={isSelected ? iconApproveActive : iconApprove}
                  onClick={() =>
                    onSelected(phone.id)}
                />
              </div>

              <div
                data-place="left"
                data-tip={`${t('toolTip.settings')}`}
              >
                <IconBtSVG
                  onClick={() =>
                    modalOptions.show('phone.edit', { phone })}
                >
                  <IconSettings fill={theme.colors.deepBlue} />
                </IconBtSVG>
              </div>
            </div>
            <div className="info">
              {phone?.deviceModel != null
                ? `${phone?.deviceManufacturer} ${phone?.deviceModel} (Android ${phone.version})`
                : '----'}
            </div>

            <WarningsWrapper>
              {
                isExpiredProxyPassword
                && (
                  <img
                    className="tooltip-action-container"
                    data-place="left"
                    data-tip={`${t('proxy.passwordExpired')}`}
                    src={warningIcon}
                    alt="Warning icon"
                  />
                )
              }
              {
                isExpiredProxyIp && (
                  <img
                    className="tooltip-action-container"
                    data-place="left"
                    data-tip={`${t('proxy.ipOutdated')}`}
                    src={ipWarningIcon}
                    alt="IP Warning icon"
                  />
                )
              }
            </WarningsWrapper>
          </PhoneControlsContainer>
        </CellContent>
      </td>
    </DataRow>
  );
};

interface DataRowProps extends UIProps {
  selected: boolean;
  expired?: boolean;
  willExpire?: boolean;
  error?: boolean;
  online: boolean;
  hasIpIssue: boolean;
}

const DataRow = styled.tr<DataRowProps>`
  background-color: ${(props: DataRowProps) =>
    (props.willExpire && !props.expired
      ? 'rgb(255 247 202)'
      : props.expired
        ? 'rgb(255 55 55 / 14%)'
        : props.selected
          ? '#F8FFF0'
          : props.theme.colors.white)};

  :hover {
    background-color: ${(props: DataRowProps) =>
    (props?.expired
      ? 'rgb(255 55 55 / 20%)'
      : props.willExpire
        ? 'rgb(255,240,200)'
        : props.theme.colors.skyBlueTint)};
  }

  .highlight-item {
    background-color: yellow;
  }

  .ovpn-config {
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallerMedium};
  }
`;

interface ExpandItemProps {
  active: boolean;
}

const ExpandItem = styled.div<ExpandItemProps>`
  width: 10px;
  height: 12px;
  background-image: url(${iconExpand});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  cursor: pointer;
  transform: rotate(
    ${(props: ExpandItemProps) =>
    (props.active ? '0' : '180deg')}
  );
`;

const RemoveDateHilight = styled.div`
  .remove-highlight {
    color: ${(props: UIProps) =>
    props.theme.colors.warning};
  }

  .expired-highlight {
    color: #ffa800;
  }
`;

const CellContent = styled.div`
  display: flex;
  height: 100%;
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  align-items: center;

  &.server_geo {
    justify-content: center;
  }

  &.last_td {
    justify-content: center;
  }

  &.usage {
    min-width: 30px;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    display: flex;
    align-items: center;
    gap: 3px;
  }

  &.traffic_column {
    width: 80px;
    justify-content: center;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
  }
`;

const PhoneInfoContainer = styled.div`
  width: 280px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

interface PhoneNameProps extends UIProps {
  online: boolean;
}

const PhoneName = styled.div<PhoneNameProps>`
  font-weight: 500;
  width: 190px;
  color: ${(prop: PhoneNameProps) =>
    prop.theme.colors[prop.online ? 'green' : 'warning']};
  cursor: pointer;
  transition: 0.1s;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }

  :hover {
    opacity: 0.5;
  }

  .highlight-item {
    color: ${(prop: PhoneNameProps) =>
    prop.theme.colors[prop.online ? 'green' : 'warning']} !important;
  }
`;

const PhoneNameSubInfo = styled.div`
  box-sizing: border-box;
  padding-top: 2px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    align-items: start;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }
`;

const PhoneNameSubInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface PhoneInfoProps {
  active: boolean;
}

const PhoneInfo = styled.div<PhoneInfoProps>`
  color: #7d7d7d;
  box-sizing: border-box;
  padding-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  display: ${(props: PhoneInfoProps) =>
    (props.active ? 'flex' : 'none')};
  flex-direction: column;
  text-align: left;
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};

  .phone-specification {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .phone-number {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smallerBold};
  }
`;

const ConnectionsContainer = styled.div`
  max-width: 260px;
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;

const ConnectionsItem = styled.div`
  :first-child {
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }

  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  position: relative;
  box-sizing: border-box;
  padding-left: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
    word-break: break-all;
    margin-top: 4px;
    width: 180px;
  }
`;

const ConnectionsCredCopy = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  cursor: pointer;
  text-transform: lowercase;
  background-image: url(${iconCopy});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: 30%;
  left: 0;
  transform: translateY(-50%);
`;

const PhoneIpContainer = styled.div`
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
  }

  .phone-ip {
    display: flex;
  }

  .phone-change-ip {
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    transition: 0.3s;
    cursor: pointer;
    height: 20px;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const PhoneTariffContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(prop: UIProps) =>
    prop.theme.sizes.font.small};
  line-height: 17px;
  padding-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  box-sizing: content-box;

  .tariff {
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    cursor: pointer;
  }

  .phone-tariff {
    max-width: 140px;
    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
    }
  }

  .phone-extensions {
    color: ${(prop: UIProps) =>
    prop.theme.colors.deepBlue};
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smallerBold};
    text-transform: uppercase;
    padding-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  }
`;

interface IconBtProps {
  icons: string;
}

const IconBt = styled.div`
  width: 20px;
  height: 15px;
  cursor: pointer;
  background-image: url(${(props: IconBtProps) =>
    props.icons});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 13px;
`;

const IconBtSVG = styled.div`
  width: 20px;
  height: 15px;
  cursor: pointer;
`;

const PhoneControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 70px;
  padding: 0 2px;

  .actions {
    display: flex;
    justify-content: center;
    width: 60px;
    margin: auto;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .info {
    width: 99%;
    text-align: center;
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
    color: ${(props: UIProps) =>
    props.theme.colors.black};
  }
`;

interface InfoLabelProps extends UIProps {
  color: 'greenMud' | 'warning' | 'deepBlue';
  isAction?: boolean;
}

const InfoLabel = styled.span<InfoLabelProps>`
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
  color: ${(props: InfoLabelProps) =>
    props.theme.colors[props.color]};
  cursor: ${(props: InfoLabelProps) =>
    (props.isAction ? 'pointer' : 'help')} !important;
`;

const IpChangeProgress = styled(IconIpChange)`
  margin: auto;
  display: flex;

  * {
    fill: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  }
`;

const WarningIpIcon = styled.div`
  background-image: url(${iconWarning});
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

interface ExpirationDateBlockProps extends UIProps {
  expirated?: boolean;
}

const ExpirationDateBlock = styled.div<ExpirationDateBlockProps>`
  color: ${(props: ExpirationDateBlockProps) =>
    (props.expirated ? props.theme.colors.warning : props.theme.colors.greenMud)};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

const TrafficLimits = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const Server = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  .country_name {
    text-align: center;
  }
`;

const WarningsWrapper = styled.div`
  position: absolute;
  top: 3%;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

  img {
    width: 20px;
    height: 20px;
  }
`;

const OVPNConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    padding: 0 0 10px 0;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 150px;
  }
`;

const ConnectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OVPNConfigInnerWrapper = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

interface AppVersionProps extends UIProps {
  relevantVersion: boolean;
  color: string;
}

export const AppVersion = styled.span<AppVersionProps>`
  color: ${(props) =>
    (props.relevantVersion ? props.color : props.theme.colors.warning)};
`;
