import React, { FunctionComponent, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Loader, ModalComponent } from '../components';
import { InformationMenu } from '../components/common/information-menu';
import { UIProps } from '../types';
import { useAuthGuard } from '../hooks';
import { getUrlParameterByName, removeURLParams } from '../utils';
import { storage } from '../services';
import { landingPath, paymentSuccessful } from '../constants';
import store from '../core/store/store';
import { initAuthorizedApp } from '../core/store/actions/app';
import { getPhoneRealInfo } from '../core/store/actions/connections';
import { TopMenuComponent } from '../components/common/TopMenuComponent';
import { CommonAppContext } from '../context/CommonAppContext';
import { OnboardingScreen } from '../screens/OnboardingScreen';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { FooterTemplate } from '../components/common/FooterTemplate';
import { SetSystemLanguageBlock } from '../components/SetSystemLanguageBlock';
import { ChangePswScreen } from '../screens/ChangePswScreen';
import { PaymentSuccesfulModal } from '../components/modals/payment-successful-modal';
import { PromocodeBanner } from '../components/PromocodeBanner';
import { PromocodeBannerContext } from '../context/PromocodeBannerContext';
import { BREAKPOINT_MOBILE } from '../utils/constants';
import { useChangeScreenSettings } from '../hooks/useChangeScreenSettings';

const GlobalWrapper = styled.div`
  background-color: #e5e5e5;
  position: relative;

  .Toastify__toast--info {
    background-color: ${(prop: UIProps) =>
    prop.theme.colors.greenMud};
  }
`;

interface ContentWrapperProps {
  showChangePswScreen: boolean;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  display: ${(props) =>
    (props.showChangePswScreen ? 'none' : 'block')};
`;

const Wrapper = styled.div`
  max-width: ${(prop: UIProps) =>
    prop.theme.sizes.maxWidth};
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  position: relative;
  height: 100%;
  min-height: calc(100vh - 110px - 225px);

  .Toastify__toast--info {
    background-color: ${(prop: UIProps) =>
    prop.theme.colors.greenMud};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    max-width: 100%;
    padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  }
`;

interface HeaderProps {
  expanded: boolean;
}

const Header = styled.div<HeaderProps>`
  background-color: ${(props) =>
    (props.expanded
      ? props.theme.colors.lightOcean
      : props.theme.colors.deepBlue)};
  height: 220px;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
`;

interface MainContentProps {
  hide?: boolean;
}
const MainContent = styled.div<MainContentProps>`
  width: 100%;
  margin-top: -250px;
  display: ${(prop) =>
    (prop.hide ? 'none' : 'block')};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
  }
`;

const AppContent = styled.div`
  width: 100%;
  background-color: ${(props: UIProps) =>
    props.theme.colors.white};
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.mediumZero};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.zero};
    padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  }
`;

const BannersWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 2001;
`;

export interface DashboardTemplateProps {
  children?: any;
}

const debounceCall = debounce(
  (callback) => {
    callback();
  },
  1000,
  {
    leading: false,
    trailing: true,
  },
);

export const DashboardTemplate: FunctionComponent<DashboardTemplateProps> = (
  props,
) => {
  const { children } = props;
  const { i18n } = useTranslation();
  const authGuard = useAuthGuard();
  const context = React.useContext(CommonAppContext);
  const { showPromocodeBanner } = React.useContext(PromocodeBannerContext);
  const { profile } = useTypedSelector(({ user }) =>
    user);
  const [expanded, setExpanded] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(
    getUrlParameterByName(paymentSuccessful) !== null,
  );

  const isSwitched = useMemo(
    () =>
      window.localStorage.getItem('switch-lang-flag'),
    [i18n.language],
  );

  const [isFinishChangePsw, setIsFinishChangePsw] = useState(false);

  const $startAppInitialization = () => {
    store.dispatch(initAuthorizedApp());
    setInterval(() => {
      store.dispatch(getPhoneRealInfo.request(null, null));
    }, 10000);
  };

  useMemo(() => {
    if (profile?.id != null) {
      context?.$loadUserOnboardingStatus()?.then(() => {});
    }
  }, [profile?.id]);

  useMemo(() => {
    debounceCall(() => {
      const token = getUrlParameterByName('tt');

      if (authGuard.isAuthorized != null && token == null) {
        $startAppInitialization();
      } else if (token != null) {
        setTimeout(() => {
          storage.addItem('SYSTEM', 'token', getUrlParameterByName('tt'));
          window.location = removeURLParams('tt') as any;
          authGuard.triggerUpdate();
        }, 100);
      } else {
        window.location.href = landingPath;
      }
    });
  }, [authGuard?.isAuthorized]);

  const { noNeedChangePswOrApiKey } = useChangeScreenSettings();

  const showChangePswScreen = !noNeedChangePswOrApiKey && !!profile && !isFinishChangePsw;

  return (
    <GlobalWrapper>
      <BannersWrapper>
        {showPromocodeBanner && <PromocodeBanner />}
        {!isSwitched && <SetSystemLanguageBlock />}
      </BannersWrapper>
      <ToastContainer />
      <OnboardingScreen />
      {showChangePswScreen && (
        <ChangePswScreen onFinish={() =>
          setIsFinishChangePsw(true)}
        />
      )}
      <ContentWrapper showChangePswScreen={showChangePswScreen}>
        <Header expanded={expanded} />
        <Wrapper>
          <Loader
            forceShow={!authGuard?.isAuthorized || context?.onboarding == null}
            opacity={false}
          />
          <ModalComponent />

          <MainContent>
            <TopMenuComponent
              isPublic={false}
              expanded={expanded}
              onExpanded={setExpanded}
            />
            <AppContent>{children}</AppContent>
            <InformationMenu />
          </MainContent>
        </Wrapper>
        <FooterTemplate />
      </ContentWrapper>

      {isPaymentSuccessful && (
        <PaymentSuccesfulModal
          setIsPaymentSuccessful={setIsPaymentSuccessful}
        />
      )}
    </GlobalWrapper>
  );
};
