import React, {
  useState, useEffect, useRef, FunctionComponent,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import iconTotalStats from '../../../assets/stats/stats_icon_1.svg';
import iconIncome from '../../../assets/stats/stats_icon_income.svg';
import iconOutcome from '../../../assets/stats/stats_icon_outcome.svg';
import iconCalendar from '../../../assets/stats/stats_calendar_icon.svg';
import { UIProps } from '../../../types';
import iconArrowBlue from '../../../assets/bullet-direction.svg';
import { CalendarAtom } from '../../atoms/CalendarAtom';
import { useOnClickOutside } from '../../../hooks';
import { DAY_IN_MS } from '../../../constants/common';
import { dateFormats, BREAKPOINT_MOBILE } from '../../../utils/constants';
import { formatDate } from '../../../utils/formatter-utils';
import { TrafficMonthlyResult, TrafficMonthlySum } from '../../../types/phones';
import { useIsMobileHook } from '../../../hooks/useIsMobileHook';

interface StatisticCalendarProps {
  selectedTimestamp?: any;
  dailyStatsMap?: TrafficMonthlyResult[];
  onChangeDate: Function;
  onDaySelect: Function;
  handleChangeDailyTrafficTab?: Function;
  handleChooseDay?: Function;
}

const getDayTimestamp = (t) =>
  +moment(t).startOf('day');

export const renderStatsTool = (stats: TrafficMonthlySum, isMobile: boolean) => {
  if (stats === undefined) {
    return null;
  }

  const inStat = (stats.in / 1024.0 / 1024.0).toFixed(1);
  const outStat = (stats.out / 1024.0 / 1024.0).toFixed(1);
  const v = (+inStat + +outStat).toFixed(1);

  return (
    <StatsToolWrapper>
      <RowStat>
        <InOutImg src={iconTotalStats} />
        <span>
          {`${v} `}
        </span>
        {
          !isMobile && (
            <MB>{' MB'}</MB>
          )
        }
      </RowStat>
      <RowStat>
        <InOutImg src={iconIncome} />
        <span>
          {`${inStat} `}
        </span>
        {
          !isMobile && (
            <MB>{' MB'}</MB>
          )
        }
      </RowStat>
      <RowStat>
        <InOutImg src={iconOutcome} />
        <span>
          {`${outStat} `}
        </span>
        {
          !isMobile && (
            <MB>{' MB'}</MB>
          )
        }
      </RowStat>

    </StatsToolWrapper>
  );
};

export const StatisticCalendar: FunctionComponent<StatisticCalendarProps> = (props) => {
  const {
    selectedTimestamp,
    onChangeDate = () => {},
    onDaySelect = () => {},
    dailyStatsMap = [],
    handleChangeDailyTrafficTab = () => {},
    handleChooseDay = () => {},
  } = props;

  const { i18n } = useTranslation();
  const locale = i18n.language;
  const calendarRef = useRef(null);
  const [showCalendarPicker, setShowCalendarPicker] = useState(false);
  const [selectDay, setSelectDay] = useState<any>(null);
  const isMobile = useIsMobileHook();

  const [monthTimestamp, setMonthTimestamp] = useState<any>(+new Date());
  const start = +moment(monthTimestamp).startOf('month').startOf('week').startOf('day');
  const end = +moment(monthTimestamp).endOf('month').endOf('week').startOf('day');

  useEffect(() => {
    if (selectedTimestamp != null) {
      setMonthTimestamp(+moment(selectedTimestamp).startOf('month'));
    }
  }, [selectedTimestamp]);

  let weeks = []; let
    startTime = start;
  while (startTime < end) {
    weeks = weeks.concat(startTime);
    startTime = +startTime + 7 * DAY_IN_MS;
  }

  useOnClickOutside(calendarRef, () =>
    setShowCalendarPicker(false));

  const renderWeekDay = (day) => {
    const tt = getDayTimestamp(+day);
    const selected = tt === selectDay;
    const dStats = dailyStatsMap.find((item) =>
      item.t === tt);
    const isToday = moment(tt).isSameOrAfter(moment().startOf('day')) && moment(tt).isSameOrBefore(moment().endOf('day'));
    const isSameMonth = moment(tt).isSameOrAfter(moment().startOf('month')) && moment(tt).isSameOrBefore(moment().endOf('month'));

    return (
      <DayInner
        isSameMonth={isSameMonth}
        isToday={isToday}
        selected={selected}
        onClick={() => {
          if (selected === false) {
            setSelectDay(tt);
            onDaySelect(tt);
          }
        }}
      >
        <div>
          {dStats === undefined
            ? null
            : (
              <CenterInner>
                {renderStatsTool(dStats, isMobile)}
              </CenterInner>
            )}
        </div>

      </DayInner>
    );
  };

  const onChangeDailyTrafficTab = (tab) => {
    handleChangeDailyTrafficTab(tab);
  };

  const onChooseDay = (timestamp) => {
    handleChooseDay(timestamp);
  };

  return (
    <Wrapper>

      <ControlBlock>
        <div className="controlls">
          <div className="labels-ctr">
            <ControlArrow
              reverse
              onClick={() => {
                onChangeDate(+moment(monthTimestamp).subtract(1, 'month').startOf('month'));
              }}
            />
            <span className="label">
              {' '}
              {`${formatDate(monthTimestamp, dateFormats.monthYear, locale)}`}
            </span>
            <ControlArrow onClick={() => {
              onChangeDate(+moment(monthTimestamp).add(1, 'month').startOf('month'));
            }}
            />
          </div>
          <div
            className="calendar-picker"
            onClick={() =>
              setShowCalendarPicker(!showCalendarPicker)}
          />
          {
            showCalendarPicker
              ? (
                <div ref={calendarRef} className="call-cont">
                  <CalendarAtom
                    navigationLabel={(p) =>
                      p?.label?.replace('г.', '')}
                    nextLabel=""
                    prevLabel=""
                    onChange={() => {}}
                    value={new Date()}
                    locale={i18n.language}
                  />
                </div>
              ) : null
          }
        </div>
      </ControlBlock>

      <ContentPlaceholder>
        <Weeks>
          {weeks.map((w) => {
            const days = Array.from(new Array(7)).map((x, i) =>
              (+w + i * DAY_IN_MS));
            return (
              <WeekRow key={`${w}`}>
                <Days>
                  {days.map((d) => {
                    const cont = renderWeekDay(d);

                    return (
                      <DayItem
                        key={`${d}`}
                        onClick={() => {
                          onChangeDailyTrafficTab(2);
                          onChooseDay(d);
                        }}
                      >
                        <DayName>
                          {formatDate(d, dateFormats.day, locale)}
                        </DayName>
                        {cont === undefined ? null
                          : (
                            <DayContentPlaceholder>
                              {cont}
                            </DayContentPlaceholder>
                          )}
                      </DayItem>
                    );
                  })}
                </Days>
              </WeekRow>
            );
          })}
        </Weeks>
      </ContentPlaceholder>

    </Wrapper>
  );
};

const ControlBlock = styled.div<UIProps>`
    color: ${(props) =>
    props.theme.colors.darkGray};
    padding-left: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    padding-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

    .call-cont{
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
    }

    .calendar-picker{
      width: 30px;
      height: 30px;
      background-color: ${(props) =>
    props.theme.colors.ocenlight};
      border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
      background-image: url(${iconCalendar});
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid transparent;

      cursor: pointer;
      :hover{
        border: 1px solid ${(props) =>
    props.theme.colors.deepBlue};
      }
    }

  .controlls{
      display: flex;
      position: relative;
      width: 285px;

      .label{
        text-transform: capitalize;
        ${(props: UIProps) =>
    props.theme.sizes.font.mediumBold};
        color: ${(props: UIProps) =>
    props.theme.colors.black};
        padding: 0 15px;
      }

      .labels-ctr{
        margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
        display: flex;
        width: 250px;
        align-items: center;
        justify-content: space-between;
      }
    }
`;

interface ControlArrowProps extends UIProps {
  reverse?: boolean;
}
const ControlArrow = styled.div<ControlArrowProps>`
  width: 30px;
  border: 1px solid transparent;
  height: 30px;
  background-color: ${(props) =>
    props.theme.colors.ocenlight};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  background-image: url(${iconArrowBlue});
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate( ${(props) =>
    (props?.reverse ? 180 : 0)}deg);
  cursor: pointer;
  :hover{
    border: 1px solid ${(props) =>
    props.theme.colors.deepBlue};
  }
`;

const InOutImg = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 1px;
  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 10px;
    height: 10px;
  }
`;

interface DayInnerProps extends UIProps {
  selected?: boolean;
  isToday?: boolean;
  isSameMonth?: boolean;
}
const DayInner = styled.div<DayInnerProps>`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    (props?.isToday ? '#E8F8F2' : props?.theme?.colors?.ocenlight)};
  cursor: ${(props) =>
    (props.isToday ? 'default' : 'pointer')};
  box-sizing: border-box;
  ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
  text-align: center;
  overflow: hidden;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  border: 1px solid ${(props) =>
    (props.selected ? props?.theme?.colors?.green : 'transparent')};
  opacity: ${(props) =>
    (props?.isSameMonth ? 1 : 0.8)};

  :hover {
    border: 1px solid ${(props) =>
    props?.theme?.colors?.deepBlue};
  }
`;
const CenterInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div<any>`
    width: 100%;
    min-height: 400px;
`;

const topHeight = 40;

const ContentPlaceholder = styled.div`
    height: calc(100% - ${topHeight}px);
    width: 100%;
`;

const Weeks = styled.div<any>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const WeekRow = styled.div<any>`
    width: 100%;
    flex: 1;
`;

const Days = styled.div<UIProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;

const DayItem = styled.div<UIProps>`
    flex: 1;
    height: 68px;
    overflow-y: auto;
    box-sizing: border-box;
    border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
    width: ${100.0 / 7}px;
    min-width: 90px;
    position: relative;
`;

const DayName = styled.div<UIProps>`
    position: absolute;
    top: 0px;
    left: 2px;
    ${(props: UIProps) =>
    props.theme.sizes.font.smallerMedium};
    color: ${(props) =>
    props?.theme?.colors?.deepBlue};

`;

const DayContentPlaceholder = styled.div`
    width: 100%;
    height: 100%;
`;

const StatsToolWrapper = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
`;

const MB = styled.span``;

const RowStat = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  margin: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
  height: 22px;
  position: relative;
  left: 8px;
`;
