import React, {
  FunctionComponent,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Highlighter from 'react-highlight-words';
import { UIProps } from '../types';
import iconWarning from '../assets/Warning/Triangle_Warning.svg';
import { Phone } from '../types/phones';
import { DashboardConfig } from '../types/dashboard';
import { BatteryStatus } from './atoms/battery-status';
import { useModalHook } from '../hooks';
import {
  formatDate, getNumEditing, getRelevantVersion, textShorter,
} from '../utils';
import iconDollar from '../assets/dollar-sign.svg';
import theme from '../styles/ApplicationTheme';
import { dateFormats } from '../utils/constants';
import { AppVersion } from './PhoneItemRowComponent';

interface PhoneGridItemComponentProps {
  phone?: Phone;
  dashboardConfigs?: DashboardConfig;
  appConfigs?: any;
  searchPhrase?: any;
}

export const PhoneGridItemComponent: FunctionComponent<PhoneGridItemComponentProps> = (props) => {
  const {
    phone, dashboardConfigs, searchPhrase, appConfigs,
  } = props;
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const modalOptions = useModalHook();

  const days = dashboardConfigs != null
      && dashboardConfigs?.configs?.tariffExpNotification != null
    ? dashboardConfigs.configs.tariffExpNotification / 24
    : 1;
  const expDate = moment(phone?.activePlans[0]?.expirationTimestamp);
  const isExpired = moment().isSameOrAfter(expDate);
  const willExpiredTomorrow = moment().isSameOrAfter(
    expDate.subtract(days, 'days'),
  );

  const tipMessage = isExpired || willExpiredTomorrow
    ? isExpired
      ? `${t('notification.gridMsgTariffExpired', {
        date: `${formatDate(expDate, dateFormats.dateTime, locale)}`,
      })}`
      : `${t('notification.gridMsgTariffWillExpire', {
        date: `${formatDate(expDate.add(10, 'days'), dateFormats.dateTime, locale)}`,
      })}`
    : phone.name;

  const appVersion = phone?.appVersion;
  const relevantVersion = getRelevantVersion(appVersion);

  return (
    <Wrapper>
      <PhoneName
        data-class="cus_tooltip_basic"
        data-type={isExpired || willExpiredTomorrow ? 'dark' : 'light'}
        data-place="top"
        data-tip={tipMessage}
        online={phone?.onlineStatus?.online}
        expired={isExpired}
        willExpire={willExpiredTomorrow}
        onClick={() =>
          (isExpired || willExpiredTomorrow
            ? modalOptions.show('phone.edit', {
              phone,
              menuKey: 'tariff',
            })
            : modalOptions.show('phone.edit', { phone }))}
      >
        <div className="title">
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={searchPhrase?.split(' ') || []}
            autoEscape
            textToHighlight={textShorter(phone.name, 20)}
          />
        </div>
        <div className="base-info">
          <div className="device-metrics">
            <div className="battery">
              {phone?.onlineStatus?.online ? (
                <BatteryStatus value={phone?.deviceMetrics?.batteryLevel} />
              ) : null}
            </div>
            <div className="connection-type">
              {phone?.onlineStatus?.online
                ? phone?.deviceMetrics?.networkType?.toLowerCase() == null
                    || phone?.deviceMetrics?.networkType?.toLowerCase() === 'none'
                  ? (
                    <span>
                      -
                    </span>
                  )
                  : (
                    <span>
                      {phone?.deviceMetrics?.networkType}
                    </span>
                  )
                : null}
              {phone?.onlineStatus?.online && phone?.ipDublicates?.length
                ? (
                  <span>
                    RPT
                  </span>
                )
                : null}
              {phone?.onlineStatus?.online
                && phone?.ipNotChnagedForSomeTime ? (
                  <WarningIpIcon
                    className="tooltip-action-container"
                    data-class="cus_tooltip_basic"
                    data-place="left"
                    data-type="light"
                    data-tip={`${t('toolTip.ipDidnotChanged', {
                      time: appConfigs?.dashboard_config?.configs
                        ?.ipNotChangeTime,
                      unit: getNumEditing(
                        appConfigs?.dashboard_config?.configs?.ipNotChangeTime,
                        i18n.language,
                        'minutes',
                      ),
                    })}`}
                  />
                ) : null}
              <AppVersion
                relevantVersion={relevantVersion}
                color={theme.colors.deepBlue}
              >
                {appVersion ? `v${appVersion}` : null}
              </AppVersion>
            </div>
          </div>
          {isExpired || willExpiredTomorrow ? <DollarIcon /> : null}
        </div>
      </PhoneName>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const DollarIcon = styled.div`
  width: 10px;
  height: 15px;
  background-image: url(${iconDollar});
  background-size: 10px;
  background-repeat: no-repeat;
`;

interface PhoneNameProps extends UIProps {
  online: boolean;
  expired?: boolean;
  willExpire?: boolean;
  hasIpIssue?: boolean | null;
}

const PhoneName = styled.div<PhoneNameProps>`
  background-color: ${(prop: PhoneNameProps) =>
    (prop?.expired || prop?.willExpire
      ? prop.theme.colors[prop.willExpire ? 'ligthYellow' : 'lightPink']
      : prop.theme.colors[prop.online ? 'lightOcean' : 'lightPink'])};

  color: ${(prop: PhoneNameProps) =>
    prop.theme.colors[prop.online ? 'greenMud' : 'warning']};
  cursor: pointer;
  transition: 0.1s;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  line-height: 1.25em;
  text-align: left;
  width: 140px;
  height: 120px;
  box-sizing: border-box;
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  border: 2px solid transparent;
  :hover {
    border: 2px solid
      ${(prop: PhoneNameProps) =>
    (prop?.expired || prop?.willExpire
      ? prop.theme.colors[prop.willExpire ? 'strongYellow' : 'warning']
      : prop.theme.colors[prop.online ? 'greenMud' : 'warning'])};

    cursor: pointer;
  }

  .connection-type {
    ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller};
    display: flex;
    align-items: center;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    justify-content: space-between;
  }

  .base-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .device-metrics {
    display: flex;
    flex-direction: column;
    min-width: 115px;
  }

  .battery {
    height: 10px;
    color: ${(prop: PhoneNameProps) =>
    prop.theme.colors?.deepBlue};
    font-weight: 600;
  }

  .title {
    height: 90%;
    cursor: pointer;
  }
`;

const WarningIpIcon = styled.div`
  background-image: url(${iconWarning});
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
`;
