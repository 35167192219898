import moment from 'moment';

export const textShorter = (value: string, cutTo = 50) =>
  (value.length > cutTo ? `${value?.slice(0, cutTo)}...` : value);

export const emptyHolder = (value: string | number, sign = '-') =>
  (value == null || value === '' ? sign : value);

export const numberWithSpaces = (x): string =>
  `${Number(+x).toFixed(2)}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const getUrlParameterByName = (key, url = window.location.href) => {
  const name = key.replace(/[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const removeURLParams = (sParam) => {
  if (!window.location.search) return window.location.href;
  let url = `${window.location.href.split('?')[0]}?`;
  const sPageURL = decodeURIComponent(window.location.search.substring(1));
  const sURLVariables = sPageURL.split('&');
  let sParameterName;
  let i;

  for (i = 0; i < sURLVariables.length; i += 1) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] !== sParam) {
      url = `${url + sParameterName[0]}=${sParameterName[1]}&`;
    }
  }

  return url.substring(0, url.length - 1);
};

export const getNumEditing = (
  number: number,
  language: string,
  type: 'days' | 'hours' | 'minutes' = 'minutes',
) => {
  const editing = {
    days: [],
    hours: [],
    minutes: [],
  };

  switch (language) {
    case 'ru':
      editing.days = ['день', 'дня', 'дней'];
      editing.hours = ['час', 'часа', 'часов'];
      editing.minutes = ['минута', 'минуты', 'минут'];
      break;
    case 'tr':
      editing.days = ['gün'];
      editing.hours = ['saat'];
      editing.minutes = ['dakika'];
      break;
    case 'th':
      editing.days = ['วัน'];
      editing.hours = ['ชั่วโมง'];
      editing.minutes = ['นาที'];
      break;
    case 'en':
    default:
      editing.days = ['days'];
      editing.hours = ['hours'];
      editing.minutes = ['minutes'];
      break;
  }

  const aEndings = editing[type];
  let iNumber = number;
  let i;

  if (language === 'ru') {
    iNumber %= 100;
    if (iNumber >= 11 && iNumber <= 19) {
      return aEndings[2];
    }
    i = iNumber % 10;
    switch (i) {
      case (1): return aEndings[0];
      case (2):
      case (3):
      case (4): return aEndings[1];
      default: return aEndings[2];
    }
  }

  return aEndings[0];
};

const normalizeLocale = (locale) => {
  switch (locale) {
    case 'ru':
      return 'ru';
    case 'tr':
      return 'tr';
    case 'th':
      return 'th';
    case 'pt':
      return 'pt';
    case 'ua':
      return 'uk';
    case 'es':
      return 'es';
    case 'hi':
      return 'hi';
    case 'vi':
      return 'vi';
    case 'zh':
      return 'zh-cn';
    case 'en':
    default:
      return 'en';
  }
};

export const formatDate = (date, format, locale) =>
  moment(date).locale(normalizeLocale(locale)).format(format);

export const setConfigDefaultSetting = (setting: boolean, flag: boolean = true) =>
  (setting === undefined
    ? flag
    : setting);
