import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { UIProps } from '../types';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { numberWithSpaces, formatDate } from '../utils';
import { BasicButton, WrapperScreen } from '../styles/ui-controls';
import ScrollWrapper from '../components/common/ScrollWrapper';
import { useModalHook } from '../hooks';
import { TransactionsContainer, TransactionsRow, ScreenInfo } from './styles';
import { dateFormats } from '../utils/constants';
import theme from '../styles/ApplicationTheme';

interface UpcomingChargesScreenProps {}

export const UpcomingChargesScreen: FunctionComponent<
UpcomingChargesScreenProps
> = () => {
  const { t, i18n } = useTranslation();

  const modalOptions = useModalHook();

  const { autoPaymentInfo, balance } = useTypedSelector(
    ({ user }) =>
      user,
  );
  const total = autoPaymentInfo?.data?.details?.reduce(
    (acc, item) =>
      acc + item.sum,
    0,
  );

  return (
    <WrapperScreen>
      <ScreenInfo
        marginTop="5px"
      >
        <div className="title">{t('wallet.nextPayment')}</div>
        <div className="description">
          {`${formatDate(autoPaymentInfo?.data?.autopaymentTimestamp, dateFormats.longDateTime, i18n.language)}`}
        </div>
      </ScreenInfo>

      <ScrollWrapper>
        <TransactionsContainer
          minWidth="800px"
          margin="16px 0"
          paddingRight="15px"
        >
          {autoPaymentInfo?.data?.details?.map((item) =>
            (
              <TransactionsRow key={item.phoneId}>
                <div className="right">
                  <div className="title">
                    <div>{item?.phoneName}</div>
                  </div>
                </div>

                <div className="left">
                  <div className="amount">
                    {`${t('wallet.RUB')}${item?.sum}`}
                  </div>
                </div>
              </TransactionsRow>
            ))}
        </TransactionsContainer>
      </ScrollWrapper>
      <Total>
        {`${t('connection.total')}: ${autoPaymentInfo?.data?.details ? autoPaymentInfo?.data?.details?.length : 0}`}
      </Total>
      <Info>
        <span className="lable">
          {t('upcomingChanges.total')}
          :
          {' '}
        </span>
        <span>
          {t('wallet.rub')}
          {numberWithSpaces(total ?? 0)}
        </span>
      </Info>
      <Info>
        <span className="lable">
          {t('upcomingChanges.balance')}
          :
          {' '}
        </span>
        <span
          style={{
            color: total <= balance ? theme.colors.green : theme.colors.warning,
          }}
        >
          {t('wallet.rub')}
          {numberWithSpaces(balance)}
        </span>
      </Info>
      <Info>
        <span className="lable">
          {t('upcomingChanges.needToPay')}
          :
          {' '}
        </span>
        <span
          style={{
            color: total <= balance ? theme.colors.black : '#e1b11c',
          }}
        >
          {t('wallet.rub')}
          {numberWithSpaces(Math.ceil(autoPaymentInfo?.data?.sum ?? 0))}
        </span>
      </Info>
      <ButtonContainer>
        <div className="btcont">
          <BasicButton
            fillWidth
            size="small"
            onClick={() => {
              modalOptions.show('balance.topUp', {
                sum:
                  autoPaymentInfo?.data?.sum == null
                    ? 0
                    : autoPaymentInfo?.data?.sum,
              });
            }}
          >
            {t('upcomingChanges.pay')}
          </BasicButton>
        </div>
      </ButtonContainer>
    </WrapperScreen>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  .btcont {
    width: 200px;
  }
`;

const Info = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.smallerMedium};
  text-align: right;

  .lable {
    ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
  }
`;

const Total = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(prop: UIProps) =>
    prop.theme.sizes.font.smaller}
`;
