import React, { useState, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { UIProps } from '../../types';
import { WrapperScreen } from '../../styles/ui-controls';
import { PaymentsUtils, windowOpen } from '../../utils';
import { useTypedSelector } from '../../core/store/selectors/type-selector';
import { BaseModalFormParams } from '../../modal';
import { analyticsClassnames } from '../../utils/analytics-utils';
import { SECRET_BAR_NUMBER } from '../../constants/common';
import store from '../../core/store/store';
import { loadUserTransaction } from '../../core/store/actions/user';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../../utils/constants';
import { ReactMD } from './ReactMarkdown';
import { getTermsAndConditionsLink } from '../../utils/instruction-links';
import theme from '../../styles/ApplicationTheme';
import { useModalHook } from '../../hooks';
import { TitleBlock } from './top-up-form';

import iconArrowLeft from '../../assets/left_arrow.svg';
import affirm from '../../assets/payments/affirm.svg';
import amazon from '../../assets/payments/amazon-pay.svg';
import americanExpress from '../../assets/payments/american-express.svg';
import applePay from '../../assets/payments/apple-pay.svg';
import bitcoin from '../../assets/payments/bitcoin.svg';
import capitalist from '../../assets/payments/capitalist.svg';
import cashApp from '../../assets/payments/cash-app.svg';
import diners from '../../assets/payments/diners.svg';
import discover from '../../assets/payments/discover.svg';
import googlePay from '../../assets/payments/google-pay.svg';
import jcb from '../../assets/payments/jcb.svg';
import klarna from '../../assets/payments/klarna.svg';
import link from '../../assets/payments/link.svg';
import masterCard from '../../assets/payments/master-card.svg';
import przelewy24 from '../../assets/payments/przelewy24.svg';
import unionPay from '../../assets/payments/unionpay.svg';
import trc from '../../assets/payments/trc.png';
import visa from '../../assets/payments/visa.svg';
import weChatPay from '../../assets/payments/we-chat-pay.svg';

const stripe = 'stripe';

const cryptoPayments = [bitcoin, capitalist, trc];

const regularPayments = [
  affirm, amazon, americanExpress, applePay,
  cashApp, diners, discover, googlePay, jcb, klarna,
  link, masterCard, przelewy24, unionPay, visa, weChatPay,
];

interface PaymentOptionProps extends BaseModalFormParams {}

export const PaymentOptions: FunctionComponent<PaymentOptionProps> = (props) => {
  const { modalParams } = props;
  const { amount } = modalParams;
  const { t, i18n } = useTranslation();

  const [isRuIP, setIsRuIP] = useState(false);
  const [secretBarCount, setSecretBarCount] = useState(0);

  const profile = useTypedSelector(({ user }) =>
    user.profile);
  const transactions = useTypedSelector(({ user }) =>
    user.transactions);
  const isCardTransactions = transactions.data?.find(
    (item) =>
      item.payment_system?.toLowerCase() === stripe
    && item.is_real_replenishment
    && item.unitpayId?.includes(stripe),
  );

  const modalOptions = useModalHook();
  const dateToShowSecretBar = moment('2024-12-01').valueOf();
  const isCryptoPaymentAvailable = !isRuIP && !isCardTransactions;

  const payKeeper = () => {
    const url = PaymentsUtils.payLink(stripe, profile?.id, +amount * 100);
    const win = windowOpen(url);
    win.focus();
  };

  const payCapitalist = async () => {
    const url = await PaymentsUtils.getCapitalistUrl(profile?.id, +amount);
    const win = windowOpen(url);
    win.focus();
  };

  useEffect(() => {
    const getUserIP = async () => {
      const { data } = await axios.get('https://iproxy.online/api-rt/ping');

      if (data?.geo?.country === 'RU') {
        setIsRuIP(true);
      } else {
        setIsRuIP(false);
      }
    };
    getUserIP();

    if (!transactions.data) {
      store.dispatch(loadUserTransaction.request(null, null));
    }
  }, []);

  return (
    <WrapperScreen>
      <TitleBlock>
        {t('wallet.balanceTopUp')}

        <GoBack
          className="goback"
          onClick={() =>
            modalOptions.show('balance.topUp', { defaultValue: amount })}
        >
          <BackButton>
            {t('wallet.back')}
          </BackButton>
          <ReactMD
            markdown={
              t('wallet.topping', { amount })
            }
            fontSize={theme.fonts.smallSemibold}
            color={theme.colors.aquamarine}
          />
        </GoBack>
      </TitleBlock>

      <PaymentOptionWrapper>
        <RegularPaymentWrapper>
          <RegularPayment
            borderRadius="10px 10px 0 0"
            maxWidth="700px"
            maxWidthTablet="100%"
            padding="20px"
            onClick={() =>
              payKeeper()}
          >
            <PaymentTitle
              onClick={() => {
                setSecretBarCount((secretBarCount) =>
                  secretBarCount + 1);
              }}
            >
              {t('wallet.paymentMethod')}
            </PaymentTitle>
            <PaymentIcons>
              {regularPayments?.map((i) =>
                (
                  <div
                    key={i}
                    className={`${analyticsClassnames.pushPayment}`}
                  >
                    <img
                      className="payment"
                      src={i}
                      alt="payment-icon"
                    />
                  </div>
                ))}
            </PaymentIcons>
            <ReactMD
              markdown={
                t('wallet.reminder', {
                  link: `[${t('wallet.termsofPayment')}](${getTermsAndConditionsLink(i18n.language)})`,
                })
              }
              fontSize={theme.sizes.font.small}
              maxWidth="900px"
            />
          </RegularPayment>

          <PeymentWarning>{t('wallet.turnOffVPN')}</PeymentWarning>
        </RegularPaymentWrapper>

        {(profile?.createdTimestamp < dateToShowSecretBar || secretBarCount >= SECRET_BAR_NUMBER) && (
          <RegularPayment
            borderRadius={theme.sizes.borderRadius.small}
            maxWidth="300px"
            maxWidthTablet="30%"
            maxWidthMobile="100%"
            padding="20px 20px 10px 20px"
            style={
              isCryptoPaymentAvailable
                ? { pointerEvents: 'none', opacity: 0.3 }
                : null
              }
            onClick={() =>
              payCapitalist()}
          >
            <PaymentTitle>
              {t('wallet.alternativePayment')}
            </PaymentTitle>

            <PaymentIcons>
              {cryptoPayments?.map((i) =>
                (
                  <div
                    key={i}
                    className={`${analyticsClassnames.pushCryptoPayment}`}
                  >
                    <img
                      className="payment"
                      src={i}
                      alt="cryptopayment-icon"
                    />
                  </div>
                ))}
            </PaymentIcons>
            {
              isCryptoPaymentAvailable && (
                <ReactMD
                  markdown={t('wallet.firstPayment')}
                  fontSize={theme.sizes.font.smallSemibold}
                />
              )
            }
            <CryptoCurrencyBlock>{t('wallet.cryptoNotice')}</CryptoCurrencyBlock>
          </RegularPayment>
        )}
      </PaymentOptionWrapper>

      <Wrapper>
        <ReactMD
          markdown={
            t('wallet.applied', {
              fees: `**${t('wallet.fees')}**`,
            })
          }
          fontSize={theme.sizes.font.small}
          maxWidth="600px"
        />
      </Wrapper>
    </WrapperScreen>
  );
};

const PaymentOptionWrapper = styled.div`
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
  }
`;

const PaymentTitle = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumBold};
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
`;

const PaymentIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  column-gap: 5px;

  .payment {
    min-width: 50px;
    max-width: 70px;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      min-width: 30px;
      max-width: 50px;
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

const GoBack = styled.div`
  display: flex;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  margin: 0 0 0 8px;
  color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};
  font-weight: normal;
  cursor: pointer;
  flex-direction: column;
  align-items: end;

  p {
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumSemibold};

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      ${(props: UIProps) =>
    props.theme.sizes.font.smallSemibold};
    }
  }

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    justify-content: space-between;
    margin-right: 48px;
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
  }
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    background-image: url(${iconArrowLeft});
    background-position: center;
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
  }
`;

const PeymentWarning = styled.div`
  color: ${(props: UIProps) =>
    props.theme.colors.white};
  ${(props: UIProps) =>
    props.theme.sizes.font.smallerSemibold};
  background-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  text-align: center;
  border-radius: 0 0 10px 10px;
  padding: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
`;

const CryptoCurrencyBlock = styled.div`
  margin: 10px 0 0 0;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
`;

const RegularPaymentWrapper = styled.div`
  position: relative;
`;

interface RegularPaymentProps {
  borderRadius: string;
  maxWidth: string;
  maxWidthTablet: string;
  maxWidthMobile?: string;
  padding: string;
}

const RegularPayment = styled.div<RegularPaymentProps>`
  display: flex;
  flex-direction: column;
  border-radius: ${(props) =>
    props.borderRadius};
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${(props) =>
    props.theme.colors.clearDark};
  padding: ${(props) =>
    props.padding};
  text-align: center;
  transition: background-color 0.3s ease;
  max-width: ${(props) =>
    props.maxWidth};
  cursor: pointer;

  &:hover {
    background-color: ${(props: UIProps) =>
    props.theme.colors.lightSky};
  }

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    max-width: ${(props) =>
    props.maxWidthTablet};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    max-width: ${(props) =>
    props.maxWidthMobile};
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
`;
