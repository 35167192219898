import React, { useState, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import {
  BasicButton,
  WrapperScreen,
} from '../styles/ui-controls';
import ScrollWrapper from '../components/common/ScrollWrapper';
import {
  TransactionsContainer,
  TransactionsRow,
  ScreenInfo,
  Error,
  Time,
  ButtonWrapper,
} from './styles';
import { dateFormats } from '../utils/constants';
import { formatDate } from '../utils/formatter-utils';
import { getExpiredPhoneConnections } from '../utils';
import { UIProps } from '../types';

export interface ExpiredConnectionsScreenProps {}

export const ExpiredConnectionsScreen: FunctionComponent<ExpiredConnectionsScreenProps> = () => {
  const { t, i18n } = useTranslation();

  const [count, setCount] = useState(1);

  const phoneConnections = useTypedSelector(
    ({ connections }) =>
      connections.data,
  );

  const expiredConnections = getExpiredPhoneConnections(phoneConnections);

  return (
    <WrapperScreen>
      <ScreenInfo
        marginTop="25px"
      >
        <div className="title">{t('expiredConnections.title')}</div>
      </ScreenInfo>

      {
        expiredConnections?.length ? (
          <Wrapper>
            <ScrollWrapper>
              <TransactionsContainer
                minWidth="830px"
                paddingRight="15px"
              >
                {expiredConnections?.slice(0, 100 * count)?.map(({ name, expDate }) =>
                  (
                    <TransactionsRow key={name + expDate}>
                      <div className="right">
                        <Time>
                          {formatDate(expDate, dateFormats.dateTimeSec, i18n.language)}
                        </Time>
                        <div className="amount">
                          {name}
                        </div>
                      </div>
                    </TransactionsRow>
                  ))}
              </TransactionsContainer>
            </ScrollWrapper>

            <ButtonWrapper>
              {Math.ceil((expiredConnections?.length ?? 0) / 100) > count && (
                <BasicButton
                  onClick={() =>
                    setCount(count + 1)}
                >
                  {t('transaction.showMore')}
                </BasicButton>
              )}
            </ButtonWrapper>
          </Wrapper>
        ) : (
          <ScrollWrapper>
            <TransactionsContainer
              minWidth="830px"
              paddingRight="15px"
            >
              <Error>
                {t('expiredConnections.error')}
              </Error>
            </TransactionsContainer>
          </ScrollWrapper>
        )
      }
    </WrapperScreen>
  );
};

const Wrapper = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
`;
