import React, { useState, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UIProps } from '../../types';
import { InputField } from '../../elements';
import {
  BasicButton,
  InputLabel,
  InputWrapper,
  WrapperScreen,
} from '../../styles/ui-controls';
import { BaseModalFormParams } from '../../modal';
import { analyticsClassnames } from '../../utils/analytics-utils';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../../utils/constants';
import { useModalHook } from '../../hooks';

interface TopUpFormProps extends BaseModalFormParams {
  defaultValue?: number;
}

const MINIMAL_PAYMENT = 5;
const DEFAULT_PAYMENT = 20;

const amounts = [10, 20, 30, 50, 100, 200];

export const TopUpForm: FunctionComponent<TopUpFormProps> = (props) => {
  const { modalParams } = props;
  const { t } = useTranslation();
  const [amount, setAmount] = useState(modalParams?.defaultValue || DEFAULT_PAYMENT);
  const modalOptions = useModalHook();

  useEffect(() => {
    if (modalParams?.sum) {
      setAmount(Math.ceil(modalParams.sum));
    }
  }, []);

  return (
    <WrapperScreen>
      <TitleBlock>
        {t('wallet.balanceTopUp')}
      </TitleBlock>

      <TopUpWrapper>
        <InputLabel>{t('wallet.selectAmount')}</InputLabel>

        <AmountsWrapper>
          {
            amounts.map((item) =>
              (
                <Amount
                  key={item}
                  isActive={amount === item}
                  onClick={() =>
                    setAmount(item)}
                >
                  $
                  {' '}
                  {item}
                </Amount>
              ))
          }
        </AmountsWrapper>

        <ElementWrapper>
          <InputWrapper>
            <InputLabel>{t('wallet.amountManually')}</InputLabel>
            <InputField
              value={amount}
              number
              error={amount < MINIMAL_PAYMENT}
              errorMsg={t('wallet.minAmount')}
              floatLabel
              onChange={({ target }) =>
                setAmount(+target.value)}
            />
          </InputWrapper>
        </ElementWrapper>
        <BasicButton
          fillWidth
          className={analyticsClassnames.topUpBalance}
          disable={amount < MINIMAL_PAYMENT}
          size="small"
          onClick={() =>
            modalOptions.show('balance.payment-options', { amount })}
        >
          {t('wallet.selectPaymentMethod')}
        </BasicButton>

        <InfoWrapper>
          <InputLabel>{t('wallet.info')}</InputLabel>
        </InfoWrapper>
      </TopUpWrapper>
    </WrapperScreen>
  );
};

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  ${(props: UIProps) =>
    props.theme.sizes.font.largeBold};
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    position: relative;
    top: 2px;
    align-items: baseline;
  }
`;

const ElementWrapper = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
`;

const TopUpWrapper = styled.div`
  max-width: 290px;

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    max-width: 100%;
  }
`;

const AmountsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;

interface AmountProps extends UIProps {
  isActive: boolean;
}

const Amount = styled.div<AmountProps>`
  text-align: center;
  border-radius: ${(props) =>
    props.theme.sizes.borderRadius.smaller};
  border: 1px solid ${(props) =>
    props.theme.colors.clearDark};
  padding: 10px 15px;
  width: 90px;
  background-color: ${(props) =>
    (props.isActive ? props.theme.colors.aquamarine : props.theme.colors.white)};
  color: ${(props) =>
    (props.isActive ? props.theme.colors.white : props.theme.colors.clearDark)};
  transition: color 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  ${(props: UIProps) =>
    props.theme.sizes.font.medium};

  &:hover {
    background-color: ${(props) =>
    props.theme.colors.aquamarine};
    color: ${(props) =>
    props.theme.colors.white};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 80px;
  }
`;

const InfoWrapper = styled.div`
  text-align: center;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
`;
