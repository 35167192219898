import React, { useRef, FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import useComponentSize from '@rehooks/component-size';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { convertTrafficPoints, formatDate, getGroupedTrafficPoints } from '../../../utils';
import { Calendar } from './Calendar';
import { PortStatistics } from './PortStatistics';
import { Port } from '../../../types/phones';
import { dateFormats, BREAKPOINT_MOBILE } from '../../../utils/constants';
import { UIProps } from '../../../types';
import theme from '../../../styles/ApplicationTheme';

interface StatisticChartProps {
  points?: any;
  groupingMinutes?: any;
  minTimestamp?: any;
  maxTimestamp?: any;
  selectedTimestamp?: any;
  portOptions: Port[];
  selectedPhonePort: number;
  setSelectedPhonePort: Function;
  setSelectedTimestamp: Function;
}

const gn = (a) =>
  (a === undefined ? undefined : (+a).toFixed(2));

const renderTooltip = (groupingMinutes, t, locale) =>
  (
    <Tooltip
      content={(tProps) => {
        const { active } = tProps;
        if (active) {
          const { payload } = tProps;
          const d = payload === undefined || payload?.[0] === undefined
            ? undefined
            : payload[0].payload;
          if (d !== undefined) {
            const fromT = moment(+d.t).startOf('minute');
            const to = +fromT + +(+groupingMinutes) * 60000;
            const timeString = `
              ${formatDate(fromT, dateFormats.timeSec, locale)} - ${formatDate(to, dateFormats.timeSec, locale)}
            `;
            return (
              <MyTooltip>
                {timeString}
                <br />
                {`${t('stats.inTraff')}: ${gn(d.in)} MB`}
                <br />
                {`${t('stats.outTraff')}: ${gn(d.out)} MB`}
              </MyTooltip>
            );
          }
        }
        return '';
      }}
    />
  );

export const StatisticChart: FunctionComponent<StatisticChartProps> = (
  props,
) => {
  const {
    points = [],
    groupingMinutes = 10,
    minTimestamp = undefined,
    selectedTimestamp = new Date(),
    maxTimestamp = undefined,
    portOptions,
    selectedPhonePort = 0,
    setSelectedPhonePort = () => {},
    setSelectedTimestamp = () => {},
  } = props;

  const ref = useRef(null);
  const { width, height } = useComponentSize(ref);
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  const mbPoints = convertTrafficPoints(points);
  let grPoints = getGroupedTrafficPoints(mbPoints, undefined, groupingMinutes);
  if (minTimestamp !== undefined && maxTimestamp !== undefined) {
    grPoints = grPoints.filter(
      (a) =>
        +a.t >= minTimestamp && +a.t <= maxTimestamp,
    );
  }

  const onChangeTimestamp = (timestamp) => {
    setSelectedTimestamp(timestamp);
  };

  return (
    <Wrapper ref={ref}>
      <div className="info-block">
        <Calendar
          currentDayTimestamp={selectedTimestamp}
          handleTimestamp={onChangeTimestamp}
        />

        <PortStatistics
          portOptions={portOptions}
          selectedPhonePort={selectedPhonePort}
          setSelectedPhonePort={setSelectedPhonePort}
        />

        <div className="stats-ss">
          <div className="info-label">
            <ColorBlock color={theme.colors.aquamarine} />
            <div className="label">{t('stats.inTraff')}</div>
          </div>
          <div className="info-label">
            <ColorBlock color={theme.colors.pinkRed} />
            <div className="label">{t('stats.outTraff')}</div>
          </div>
        </div>
      </div>
      <ResponsiveContainer width={width > 700 ? 700 : width} height="100%">
        <BarChart
          width={width > 700 ? 700 : width}
          height={height}
          data={grPoints}
          barSize={20}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="t"
            scale="point"
            tickFormatter={(t) =>
              formatDate(t, dateFormats.time, locale)}
          />
          <YAxis />
          {renderTooltip(groupingMinutes, t, locale)}
          <Bar dataKey="in" stackId="a" fill={theme.colors.aquamarine} radius={[0, 0, 5, 5]} />
          <Bar dataKey="out" stackId="a" fill={theme.colors.pinkRed} radius={[5, 5, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

interface ColorBlockProps {
  color?: any;
}

const ColorBlock = styled.div<ColorBlockProps>`
  width: 22px;
  height: 22px;
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  position: relative;
  background-color: ${(props) =>
    props?.color || 'gray'};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 350px;

  position: relative;
  clear: both;

  .stats-ss {
    display: flex;
    justify-content: space-between;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  }

  .info-label {
    display: flex;
    align-items: center;

    .label {
      ${(props: UIProps) =>
    props.theme.sizes.font.smallerMedium};
      color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
      margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
    }
  }

  .info-block {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${BREAKPOINT_MOBILE}px) {
      flex-direction: column;
      align-items: start;
      gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
      margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
    }
  }
`;

const MyTooltip = styled.div`
  padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
  border: 1px solid whitesmoke;
  background: rgba(255, 255, 255, 0.99);
`;
