import React, {
  useEffect, useRef, useState, FunctionComponent,
} from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { CalendarAtom } from '../../atoms/CalendarAtom';

import arrowExpand from '../../../assets/arrow_expand.svg';
import { useOnClickOutside } from '../../../hooks';
import { DAY_IN_MS } from '../../../constants/common';
import { dateFormats, BREAKPOINT_MOBILE } from '../../../utils/constants';
import { formatDate } from '../../../utils/formatter-utils';
import { UIProps } from '../../../types/theme';

interface CalendarProps {
  currentDayTimestamp: number;
  handleTimestamp: Function;
}

export const Calendar: FunctionComponent<CalendarProps> = (props) => {
  const { currentDayTimestamp, handleTimestamp } = props;
  const [dayTimestamp, setDayTimestamp] = useState(currentDayTimestamp);
  const [pickerVisible, setPickerVisible] = useState(false);
  const ref = useRef(null);
  const { i18n } = useTranslation();

  const onChange = (e) => {
    setDayTimestamp(+e);
    setPickerVisible(false);
    handleTimestamp(+e);
  };

  useOnClickOutside(ref, () =>
    setPickerVisible(false));

  useEffect(() => {
    setDayTimestamp(currentDayTimestamp);
  }, [currentDayTimestamp]);

  return (
    <Wrapper ref={ref}>
      {pickerVisible ? (
        <div className="calendar-container">
          <CalendarAtom
            navigationLabel={(p) =>
              p?.label?.replace('г.', '')}
            nextLabel=""
            prevLabel=""
            onChange={onChange}
            value={new Date(currentDayTimestamp)}
            locale={i18n.language}
          />
        </div>
      ) : null}

      <DateTimePickerPlaceholder>
        <Side
          onClick={() => {
            setDayTimestamp(+dayTimestamp - DAY_IN_MS);
            handleTimestamp(+dayTimestamp - DAY_IN_MS);
          }}
        >
          <ArrowButton icon={arrowExpand} modificator="prev" />
        </Side>
        <CurDateVal
          onClick={() => {
            setPickerVisible(true);
          }}
        >
          {formatDate(dayTimestamp, dateFormats.shortDate, i18n.language)}
        </CurDateVal>
        <Side
          onClick={() => {
            setDayTimestamp(+dayTimestamp + DAY_IN_MS);
            handleTimestamp(+dayTimestamp + DAY_IN_MS);
          }}
        >
          <ArrowButton icon={arrowExpand} modificator="next" />
        </Side>
      </DateTimePickerPlaceholder>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .calendar-container {
    position: absolute;
    z-index: 1000;
  }
`;

const CurDateVal = styled.div`
  font-weight: bold;
`;

const Side = styled.div`
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DateTimePickerPlaceholder = styled.div`
  cursor: pointer;
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    margin: ${(props: UIProps) =>
    props.theme.sizes.padding.smallZero};
  }
`;

interface ArrowButtonProps {
  icon?: string;
  modificator?: string;
}

const ArrowButton = styled.button<ArrowButtonProps>`
  position: relative;
  width: 32px;
  height: 32px;
  background-color: ${(props: UIProps) =>
    props.theme.colors.lightOcean};
  border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
  outline: none;
  border: none;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    width: 16px;
    height: 16px;

    background-image: url(${(props: ArrowButtonProps) =>
    props.icon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    transform: ${(props: ArrowButtonProps) =>
    (props.modificator === 'prev' ? 'rotate(90deg)' : 'rotate(-90deg)')};
  }
`;
