import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { UIProps, UserRef } from '../types';
import { useTypedSelector } from '../core/store/selectors/type-selector';
import { formatDate, showNotification, windowOpen } from '../utils';
import { WrapperScreen } from '../styles/ui-controls';
import { ConfirmForm } from '../components/common/ConfirmForm';
import { ScreenInfo } from './styles';

import iconCopy from '../assets/coppy_icon.svg';
import iconRemove from '../assets/phone_icons/remove_icon.svg';
import iconIncome from '../assets/referalicons/income.svg';
import iconPiggy from '../assets/referalicons/piggy.svg';
import iconSCart from '../assets/referalicons/shoppingcart.svg';
import iconUser from '../assets/referalicons/user.svg';
import iconTg from '../assets/referalicons/tg.svg';
import {
  LINK_REL,
  TARGET_BLANK,
  dateFormats,
  BREAKPOINT_MOBILE,
  telegramSupport,
} from '../utils/constants';
import store from '../core/store/store';
import { getInvitedUsers, removeReferralCode, addReferralCode } from '../core/store/actions/user';
import { ReactMD } from '../components/common/ReactMarkdown';
import theme from '../styles/ApplicationTheme';

const IconStats = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  allign-items: start;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    width: 25px;
    margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .info {
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    font-weight: 600;
    color: ${(props: UIProps) =>
    props.theme.colors.black};
    text-align: center;

    .label {
      ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
      text-transform: uppercase;
      color: ${(props: UIProps) =>
    props.theme.colors.darkGray};
      font-weight: normal;
      margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column;
    align-items: start;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};

    .icon {
      margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
    }

    .stat {
      flex-direction: row;
      justify-content: center;
      gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
    }

    .info {
      display: flex;
      align-items: center;
      gap: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};

      .label {
        margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
      }
    }
  }
`;

const RefContent = styled.div`
  margin: ${(props: UIProps) =>
    props.theme.sizes.padding.mediumZero};
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  gap: ${(props: UIProps) =>
    props.theme.sizes.gap.large};
  align-items: start;

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    flex-direction: column-reverse;
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }

  .title {
    color: ${(props: UIProps) =>
    props.theme.colors.dark};
    ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};
  }

  .ref-link {
    color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
    ${(props: UIProps) =>
    props.theme.sizes.font.small};
    font-weight: 500;
    position: relative;
    display: flex;
    word-break: break-word;
    flex-wrap: wrap;
    align-items: center;
    margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
    gap: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

const PartnersList = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  max-height: 150px;
  overflow: auto;

   ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props: UIProps) =>
    props.theme.colors.scrollBg};
    border-radius: 3px;
    cursor: pointer;
  }
`;

const PartnerTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.medium};

  thead {
 ${(props: UIProps) =>
    props.theme.sizes.font.smaller};
 color: ${(props: UIProps) =>
    props.theme.colors.darkGray};

 th {
   text-align: left;
   font-weight: normal;
   border-bottom: 1px solid rgba(191, 191, 191, 0.4);

   .content {
  margin-bottom: 8px;
   }
 }
  }

  tbody {
 ${(props: UIProps) =>
    props.theme.sizes.font.small};
 color: ${(props: UIProps) =>
    props.theme.colors.black};

 td {
   border-bottom: 1px solid rgba(191, 191, 191, 0.4);

 }

 tr {
   :last-child {
  td {
    border-bottom: none;
  }
   }
 }

 .content {
   padding: 12px 0;
 }
  }
`;

const URLIcon = styled.div`
  width: 12px;
  height: 12px;
  background-image: url(${iconCopy});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  :hover {
 box-shadow: 0 0 10px ${(props: UIProps) =>
    props.theme.colors.deepIndigo};
  }
`;

const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  background-image: url(${iconRemove});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  :hover {
 box-shadow: 0 0 10px ${(props: UIProps) =>
    props.theme.colors.deepIndigo};
  }
`;

const WithdawInfo = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: ${(props: UIProps) =>
    props.theme.colors.black};

  a {
 color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
 font-weight: 600;
 text-decoration: none;

 :hover {
   opacity: 0.8;
 }
  }
`;

const Support = styled.div`
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.midSmall};
  position: relative;
  display: flex;
  align-items: center;
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
  cursor: pointer;

  :hover {
 opacity: 0.8;
  }

  :before {
 width: 15px;
 height: 15px;
 display: block;
 content: '';
 background-image: url(${iconTg});
 margin-right: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
  }
`;

const InputWrapper = styled.div`
 .info {
  color: ${(props: UIProps) =>
    props.theme.colors.dark};
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
 }

 .wrong-input {
  color: ${(props: UIProps) =>
    props.theme.colors.warning};
  ${(props: UIProps) =>
    props.theme.sizes.font.small};
  margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
 }

 .input-wrapper {
  margin-bottom: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
 }
`;

const Input = styled.input`
 display: inline-block;
 box-sizing: border-box;
 width: 250px;
 height: 30px;
 padding: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
 border: 1px solid
 ${(props: UIProps) =>
    props.theme.colors.darkGray};
 border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
 outline: none;
 font: 112.5%/1.45em "Montserrat", sans-serif;

 ::placeholder {
 font: 112.5%/1.45em "Montserrat", sans-serif;
 }
 :disabled{
 background-color: #5f5f5f1c;
 }


 ${(props: UIProps) =>
    props.theme.sizes.font.small};
 :focus {
 border-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};
 box-shadow: 0px 0px 6px rgba(36, 78, 178, 0.2);
 }
`;

const Button = styled.button`
 display: inline-block;
 min-width: 155px;
 position: relative;
 box-sizing: border-box;

 padding: ${(props: UIProps) =>
    props.theme.sizes.gap.smaller};
 margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
 border-radius: ${(props: UIProps) =>
    props.theme.sizes.borderRadius.smaller};
 border: none;
 color: ${(props: UIProps) =>
    props.theme.colors.white};
 background-color: ${(props: UIProps) =>
    props.theme.colors.deepBlue};

 text-align: center;
 cursor: pointer;

 ${(props: UIProps) =>
    props.theme.sizes.font.small};
 transition: .2s;

 &:hover {
 opacity: .8;
 }

 @media (max-width: ${BREAKPOINT_MOBILE}px) {
 display: flex;
 align-items: center;
 justify-content: center;
 margin-left: ${(props: UIProps) =>
    props.theme.sizes.gap.zero};
 margin-top: ${(props: UIProps) =>
    props.theme.sizes.gap.small};
 }
`;

const RefWrapper = styled.div``;

const AffiliateProgram = styled.div`
  ${(props: UIProps) =>
    props.theme.sizes.font.mediumMedium};
`;

export interface ReferralScreenProps { }
export function ReferralScreen() {
  const { t, i18n } = useTranslation();
  const { profile } = useTypedSelector(({ user }) =>
    user);
  const referralCodes = useMemo(() =>
    profile?.referralCodes, [profile]);
  const { invitedUsers } = useTypedSelector(({ user }) =>
    user);
  const { transactions } = useTypedSelector(({ user }) =>
    user);
  const [thisMonth, setThisMonth] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalPurchase, setTotalPurchase] = useState(0);

  const [inputValue, setInputValue] = useState('');
  const [urlIsTaken, setUrlIsTaken] = useState(false);
  const [urlToDelete, setUrlToDelete] = useState('');
  const [confirmDeletingUrl, setConfirmDeletingUrl] = useState(false);
  const [wrongCharacter, setWrongCharacter] = useState(false);
  const [wrongLinkLength, setWrongLinkLength] = useState(false);
  const [wrongListLength, setWrongListLength] = useState(false);

  const invitedUsersData = invitedUsers?.data;
  const invitedUsersLength = invitedUsersData?.length;

  const calculateMoney = (userId) => {
    if (transactions?.data != null && transactions?.data.length) {
      const trs = transactions?.data.filter((a) =>
        (a?.friendId && a?.friendId === userId));
      return Math.round(trs.reduce((sm, a) =>
        (+sm + +a.amount), 0));
    }
    return 0;
  };

  const calculateMonthMoney = (userId) => {
    if (transactions?.data != null && transactions?.data.length) {
      const trs = transactions?.data
        .filter((a) =>
          (a?.friendId && a?.friendId === userId))
        .filter((a) => {
          const transDate = moment(a?.timestamp);
          if (a?.timestamp) {
            return transDate.isSameOrBefore(moment().startOf('month')) && transDate.isSameOrAfter(moment().endOf('month'));
          }
          return null;
        });
      return Math.round(trs.reduce((sm, a) =>
        (+sm + +a.amount), 0));
    }
    return 0;
  };

  const calculateRefPurchase = (userId) => {
    if (transactions?.data != null && transactions?.data.length) {
      const trs = transactions?.data.filter((a) =>
        (a?.friendId && a?.friendId === userId));
      return trs.length;
    }
    return 0;
  };

  const addUrl = async (url: string) => {
    setWrongCharacter(false);
    setUrlIsTaken(false);
    setWrongListLength(false);
    setWrongLinkLength(false);

    if (referralCodes?.includes(url.toUpperCase())) {
      setUrlIsTaken(true);
      return;
    }

    if (referralCodes?.length >= 5) {
      setWrongListLength(true);
      return;
    }

    if (url.length < 4) {
      setWrongLinkLength(true);
      return;
    }

    const reg = /^[A-z0-9]+$/;

    if (!reg.test(url)) {
      setWrongCharacter(true);
      return;
    }

    store.dispatch(
      addReferralCode.request({
        code: url,
        callback: () => {
          setInputValue('');
          setWrongCharacter(false);
          setUrlIsTaken(false);
          setWrongListLength(false);
          setWrongLinkLength(false);
        },
      }),
    );
  };

  const onApprove = async (url: string) => {
    store.dispatch(removeReferralCode.request({
      code: url,
      callback: () => {
        setConfirmDeletingUrl(false);
        setWrongCharacter(false);
        setWrongListLength(false);
        setWrongLinkLength(false);
      },
    }));
  };

  useEffect(() => {
    store.dispatch(getInvitedUsers.request(null, null));
  }, []);

  useEffect(() => {
    const income = invitedUsersData?.reduce(
      (accum, value) =>
        accum + calculateMoney((value as UserRef).id),
      0,
    );

    const monthIncome = invitedUsersData?.reduce(
      (accum, value) =>
        accum + calculateMonthMoney((value as UserRef).id),
      0,
    );

    const purchase = invitedUsersData?.reduce(
      (accum, value) =>
        accum + calculateRefPurchase((value as UserRef).id),
      0,
    );

    setTotalPurchase(purchase);
    setTotalIncome(income);
    setThisMonth(monthIncome);
  }, [invitedUsers]);

  const inviteLink = 'https://iproxy.online/invite/friend/';

  return (
    <WrapperScreen>

      <ScreenInfo
        maxWidth="940px"
        marginTop="25px"
        justifyContent
        alignItems
      >
        <div className="title">{t('referral.title')}</div>
        <div className="description">{t('referral.description')}</div>
        <div className="sub-description">{t('referral.subDescription')}</div>
        <ReactMD
          markdown={
            t('referral.warning', {
              prohibited: `**${t('referral.prohibited')}**`,
              cancelBonuses: `**${t('referral.cancelBonuses')}**`,
            })
          }
          fontSize={theme.sizes.font.small}
          color={theme.colors.warning}
        />
      </ScreenInfo>

      <RefContent>
        <RefWrapper>
          <div className="title">{t('referral.link')}</div>
          <div className="ref-link">
            {`${inviteLink}${profile != null ? profile.id : ''}`}
            <URLIcon onClick={() => {
              copy(`${inviteLink}${profile != null ? profile.id : ''}`);
              showNotification(`${t('notification.urlCopied')}`);
            }}
            />
          </div>
          {
            referralCodes?.map((url) =>
              (
                <div key={url} className="ref-link">
                  {`${inviteLink}${url != null ? url : ''}`}
                  <URLIcon onClick={() => {
                    copy(`${inviteLink}${url != null ? url : ''}`);
                    showNotification(`${t('notification.urlCopied')}`);
                  }}
                  />
                  <RemoveIcon onClick={() => {
                    setConfirmDeletingUrl(true);
                    setUrlToDelete(url);
                    setWrongCharacter(false);
                    setWrongListLength(false);
                    setWrongLinkLength(false);
                  }}
                  />
                </div>
              ))
          }
        </RefWrapper>

        <InputWrapper>
          <div className="info">{t('referral.maximumUrls')}</div>
          <div className="input-wrapper">
            <Input
              type="text"
              value={inputValue}
              placeholder={t('referral.inputLength')}
              onChange={(e) =>
                setInputValue(e.target.value)}
            />
            <Button
              type="button"
              onClick={() =>
                addUrl(inputValue)}
            >
              {t('referral.addNewUrl')}
            </Button>
          </div>

          {
            urlIsTaken
            && <div className="wrong-input">{t('referral.urlIsTaken')}</div>
          }

          {
            wrongCharacter
            && <div className="wrong-input">{t('referral.characterInfo')}</div>
          }

          {
            wrongListLength
            && <div className="wrong-input">{t('referral.maximumUrls')}</div>
          }

          {
            wrongLinkLength
            && <div className="wrong-input">{t('referral.minimumUrlLength')}</div>
          }

          {
            confirmDeletingUrl
            && (
              <ConfirmForm
                approveText={t('referral.deleteUrl')}
                cancelText={t('referral.cancelDelete')}
                text={`${t('referral.confirmDelete')} ${urlToDelete}?`}
                onApprove={() =>
                  onApprove(urlToDelete)}
                onCancel={() =>
                  setConfirmDeletingUrl(false)}
              />
            )
          }
        </InputWrapper>

      </RefContent>

      <AffiliateProgram>
        {t('referral.partners')}
        {' '}
        (
        {invitedUsersLength}
        )
      </AffiliateProgram>
      <PartnersList>
        {invitedUsersLength > 0 ? (
          <PartnerTable>
            <thead>
              <tr>
                <th>
                  <div className="content">{t('referral.date')}</div>
                </th>
                <th>
                  <div className="content">{t('referral.rewards')}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                invitedUsersData?.map((refuf: UserRef) =>
                  (
                    <tr key={refuf.id}>
                      <td>
                        <div
                          className="content"
                        >
                          {formatDate(refuf?.createdTimestamp, dateFormats.longDateTime, i18n.language)}
                        </div>
                      </td>
                      <td>
                        <div className="content">
                          {t('wallet.rub')}
                          {calculateMoney(refuf.id)}
                        </div>
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </PartnerTable>
        ) : null}
      </PartnersList>

      <IconStats>
        <div className="stat">
          <img className="icon" src={iconIncome} alt="icon" />
          <div className="info">
            {t('wallet.rub')}
            {thisMonth}
            <div className="label">{t('referral.thisMonth')}</div>
          </div>
        </div>
        <div className="stat">
          <img className="icon" src={iconPiggy} alt="" />
          <div className="info">
            {t('wallet.rub')}
            {totalIncome}
            <div className="label">{t('referral.rewards')}</div>
          </div>
        </div>

        <div className="stat">
          <img className="icon" src={iconUser} alt="icon" />
          <div className="info">
            {invitedUsersLength}
            <div className="label">{t('referral.signUp')}</div>
          </div>
        </div>
        <div className="stat">
          <img className="icon" src={iconSCart} alt="iproxy.online" />
          <div className="info">
            {totalPurchase}
            <div className="label">{t('referral.purchase')}</div>
          </div>
        </div>
      </IconStats>

      <WithdawInfo>
        {t('referral.withdraw')}
        <a href={telegramSupport} target={TARGET_BLANK} rel={LINK_REL}>{t('referral.withdraw2')}</a>
      </WithdawInfo>
      <Support onClick={() =>
        windowOpen(telegramSupport)}
      >
        iproxy_online_support
      </Support>
    </WrapperScreen>
  );
}
