import { useMemo } from 'react';
import { DAY_IN_MS, MONTH_IN_MS, YEAR_IN_MS } from '../constants/common';
import { useTypedSelector } from '../core/store/selectors/type-selector';

export const useChangeScreenSettings = () => {
  const { profile } = useTypedSelector(({ user }) =>
    user);

  const deadline = (!profile?.lastUpdateApiKeyDate
    ? profile?.lastChangePswDate ?? 0
    : Math.min(
      profile?.lastChangePswDate ?? 0,
      profile?.lastUpdateApiKeyDate,
    ))
    + YEAR_IN_MS
    + MONTH_IN_MS;

  const daysLeft = Math.ceil((deadline - Date.now()) / DAY_IN_MS);

  const noNeedChangePswOrApiKey = useMemo(
    () =>
      +Date.now() - (profile?.lastChangePswDate ?? 0) < YEAR_IN_MS
      && (+Date.now() - (profile?.lastUpdateApiKeyDate ?? 0) < YEAR_IN_MS
        || !profile?.api_key),
    [profile?.lastChangePswDate, profile?.lastUpdateApiKeyDate, profile],
  );

  return {
    deadline,
    daysLeft,
    noNeedChangePswOrApiKey,
  };
};
